import BaseView from '../../../js/base-view';
import Fetcher from '../../../js/fetcher';

import Filters from '../../molecules/filters/filters';

const DATA_ATTRIBUTE_UID = 'data-article-list';

const FILTERS_SELECTOR = '.filters';
const CONTAINER_SELECTOR = '.article-list__list';
const PAGINATION_BUTTON_SELECTOR = '.article-list__pagination a';
const FILTERS_BTN_SELECTOR = '.filters__item a,.filters__reset';

const CLASS_IS_LOADING = 'is-loading';

export default class ArticleList extends BaseView {
	/**
	 * Init function
	 */
	initialize() {
		// fetch config from the global scope
		// NOTE: we do this because we need to pass json data from views (for the "query" parameter),
		//       and it's quite tricky and error-prone to pass it through html data-attributes
		//       (mostly because escaping is not consistent between js/php)
		const configId = this.element.getAttribute( DATA_ATTRIBUTE_UID );
		if ( ! window.supt || ! window.supt.articleList || ! window.supt.articleList[ configId ] ) {
			console.error( 'Could not load config' );
			return;
		}

		const config = window.supt.articleList[ configId ];

		this.props = {
			texts: config.texts,
			isFake: config.isFake,
		};

		// Get references to DOM elements & view
		this.refs = {
			filters: new Filters( this.getScopedElement( FILTERS_SELECTOR ) ),
			container: this.getScopedElement( CONTAINER_SELECTOR ),
			paginationBtn: this.getScopedElement( PAGINATION_BUTTON_SELECTOR ),
		};

		// Init the filters view
		this.refs.filters.initialize();

		// Init the fetching objects
		this.filtersFetcher = new Fetcher( {
			action: 'article-list-filters',
			query: {
				post_type: this.refs.filters.getPostType(),
			},
		} );

		if ( this.refs.paginationBtn ) {
			this.paginationFetcher = new Fetcher( {
				action: 'article-list-pagination',
			} );
		}

		this.bindEvents();
	}

	bindEvents() {
		this.on( 'click', FILTERS_BTN_SELECTOR, this.onFilterClick.bind( this ) );
		if ( this.refs.paginationBtn ) {
			this.on( 'click', PAGINATION_BUTTON_SELECTOR, this.onPaginationClick.bind( this ) );
		}
	}

	// #region Event Handlers

	/**
	 * When user clicks on a filter button tag
	 *
	 * @param {Event} ev original event
	 */
	onFilterClick( ev ) {
		ev.preventDefault();
		this.loadFilter( ev.target.href );
	}

	/**
	 * When user clicks on the pagination button
	 *
	 * @param {Event} ev original event
	 */
	onPaginationClick( ev ) {
		ev.preventDefault();
		this.loadPagination();
	}

	// #endregion

	// #region Actions

	loadFilter( url ) {
		// toggle UI state to "loading…"
		this.toggleLoadingState( true );

		this.filtersFetcher.fetch( url )
			.then( ( result ) => this.handleContentLoaded( result ) )
			.catch( ( error ) => console.error( error ) );
	}

	/**
	 * Load the next page and inject it
	 */
	loadPagination() {
		// toggle UI state to "loading…"
		this.toggleLoadingState( true );

		// fetch (real or fake)
		this.paginationFetcher.fetch( this.refs.paginationBtn.href )
			.then( ( result ) => this.handleContentLoaded( result ) )
			.catch( ( error ) => console.error( error ) );
	}

	/**
	 * When we receive new content
	 *
	 * @param {Object} data the data received
	 */
	handleContentLoaded( data ) {
		// Update the DOM
		if ( data.filters ) {
			this.refs.filters.updateDOM( data.filters );
		}
		this.updatePostsDOM( data.posts, ! data.filters, data.hasCards );

		// toggle UI state to "finished loading"
		this.toggleLoadingState( false );

		// Update page History // TODO Enhance stored state 🧐?!
		window.history.pushState( {}, '', decodeURIComponent( data.pagination.current ) );

		// Handle the state of the
		if ( this.refs.paginationBtn ) {
			this.togglePaginationState( data.pagination );
		}
	}

	// #endregion

	// #region DOM manipulation

	updatePostsDOM( html, append = true, count ) {
		if ( append ) {
			this.refs.container.insertAdjacentHTML( 'beforeend', html );
		}
		else {
			this.refs.container.className = this.refs.container.className.replace( /has-\d+-cards/, `has-${ count }-cards` );
			this.refs.container.innerHTML = html;
		}
	}

	/**
	 * Set/unset loading state on the UI
	 *
	 * @param {boolean} isLoading is the content loading?
	 */
	toggleLoadingState( isLoading ) {
		if ( isLoading ) {
			this.element.classList.add( CLASS_IS_LOADING );
			if ( this.refs.paginationBtn ) {
				this.refs.paginationBtn.classList.add( CLASS_IS_LOADING );
				this.refs.paginationBtn.innerText = this.props.texts.loading;
			}
		}
		else {
			this.element.classList.remove( CLASS_IS_LOADING );
			if ( this.refs.paginationBtn ) {
				this.refs.paginationBtn.classList.remove( CLASS_IS_LOADING );
				this.refs.paginationBtn.innerText = this.props.texts.initial;
			}
		}
	}

	/**
	 * Handle the pagination button state
	 *
	 * @param {any} pagination
	 */
	togglePaginationState( pagination ) {
		if ( pagination.next ) {
			// Enable
			this.refs.paginationBtn.href = pagination.next;
			this.refs.paginationBtn.textContent = this.props.texts.initial;
			this.refs.paginationBtn.removeAttribute( 'disabled' );
		}
		else {
			// disable
			this.refs.paginationBtn.textContent = this.props.texts.maxReached;
			this.refs.paginationBtn.setAttribute( 'disabled', 'disabled' );
		}
	}

	// #endregion
}
