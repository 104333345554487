import BaseView from '../../../js/base-view';

const CLASS_EXPANDED = 'is-expanded';
const ICON_EXPAND = '#expand';
const ICON_COLLAPSE = '#collapse';

const SELECTOR_ICON = '.faq-category__icon use';
const SELECTOR_QUESTION_HEAD = '.faq-question__head';

export default class AccordeonItem extends BaseView {
	initialize() {
		this.state = {
			isExpanded: this.element.classList.contains( CLASS_EXPANDED ),
		};

		this.refs = {
			icon: this.element.querySelector( SELECTOR_ICON ),
			questionsToggle: Array.from( this.element.querySelectorAll( SELECTOR_QUESTION_HEAD ) ),
		};

		this.toggleIcon();
		if ( this.state.isExpanded ) {
			this.onOpen();
		}
		this.bindEvents();
	}

	bindEvents() {
		this.on( 'open', this.onOpen.bind( this ) );
		this.on( 'close', this.onClose.bind( this ) );
	}

	onOpen() {
		this.state.isExpanded = true;

		const maxToggleHeight = this.refs.questionsToggle
			.reduce( ( maxHeight, toggle ) => Math.max( maxHeight, toggle.offsetHeight ), 0 );

		this.refs.questionsToggle.forEach( ( toggle ) => {
			// eslint-disable-next-line no-param-reassign
			toggle.style.height = `${ maxToggleHeight }px`;
		} );

		this.toggleIcon();
	}

	onClose() {
		this.state.isExpanded = false;
		this.toggleIcon();
		this.refs.questionsToggle.forEach( ( toggle ) => {
			// eslint-disable-next-line no-param-reassign
			toggle.style.height = null;
		} );
	}

	open() {

	}

	toggleIcon() {
		this.refs.icon.setAttribute( 'xlink:href', this.state.isExpanded ? ICON_COLLAPSE : ICON_EXPAND );
	}
}
