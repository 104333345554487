
import BaseView from '../../../js/base-view';
import AccordeonItem from './accordeon-item';

const ATTR_NAME = 'data-accordeon';
const ATTR_ITEM = 'data-accordeon-item';

export default class Accordeon extends BaseView {
	init() {
		this.props = {
			name: this.element.getAttribute( ATTR_NAME ),
		};
		this.props.itemSelector = `[${ ATTR_ITEM }="${ this.props.name }"]`;

		this.refs = {
			items: Array.from( this.element.querySelectorAll( this.props.itemSelector ) )
				.map( ( el ) => new AccordeonItem( el ).init() ),
		};
	}

	bind() {
		this.init();
		this.on( 'open', this.props.itemSelector, this.onItemOpen.bind( this ), false, { capture: false } );
	}

	onItemOpen( event ) {
		if ( event.target.getAttribute( ATTR_ITEM ) !== this.props.name ) {
			return;
		}

		this.closeItems( this.refs.items.find( ( item ) => item.element === event.target ) );
	}

	closeItems( exclude = null ) {
		this.refs.items
			.filter( ( item ) => item !== exclude )
			.forEach( ( item ) => item.close() );
	}
}
