import axios from 'axios';

// HTTP Request cancelling
const httpRequests = new Map();

function requestCancelHandler( requestName, cancelToken, id = 0 ) {
	const requestId = `${ requestName }-${ id }`;
	const previousRequest = httpRequests.get( requestId );
	if ( previousRequest ) {
		previousRequest.cancel();
		httpRequests.delete( requestId );
	}
	httpRequests.set( requestId, { cancel: cancelToken.cancel } );
}

export default function( {
	url,
	method = 'get',
	data = null,
	params = {},
	config = {},
	name = 'foo',
	auth = null,
	id = null,
} ) {
	return new Promise( ( resolve, reject ) => {
		const cancelToken = axios.CancelToken.source();
		requestCancelHandler( name, cancelToken );

		// eslint-disable-next-line no-param-reassign
		config.cancelToken = cancelToken.token;

		axios.request( {
			url, method, data, params, config, auth,
		} )
			.then( ( response ) => {
				resolve( { ...response, id } );
			} )
			.catch( reject );
	} );
}
