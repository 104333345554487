/*
 * README
 * ======
 * The logic of this component is quite complex, so a little intro wouldn't hurt.
 *
 * For managing the UI updates (visual and functional), we rely on a state object (this.state).
 * The flow basically goes like this:
 * 1. User generates an event (click, mouseenter, keyup,…)
 * 2. The event is bound in `bindEventListeners` to a method prefixed by `handle`
 * 3. The event handler updates the state with `setState({…})`
 * 4. `setState` detects changes and calls helpers to update the UI
 *
 * In short: User Event > State update > UI update
 *
 * IMPORTANT NOTES for future modifications:
 * - we READ from this.state and WRITE to the dom, because…
 *   - reading the dom is less performant than reading a js object
 *   - we would loose the concept of a single-source of truth, which would be confusing
 * - we use TimelineLite (gsap) for animations in this case, for 2 reasons…
 *   - to have a simpler codebase (css started to be overwhelming to read and maintain)
 *   - to be able to handle page loading (we have to stop the animation when the backdrop
 *     covers the screen, to wait for the next page to be loaded)
 * - since the codebase is big, we make use of [regions](https://code.visualstudio.com/updates/v1_17#_folding-regions)
 *   a native feature in most editors, including VSCode
 */

// import TweenLite from 'gsap/TweenLite';
import TimelineLite from 'gsap/TimelineLite';
import { Power2 } from 'gsap/EasePack';
import CSSPlugin from 'gsap/CSSPlugin';
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

import BaseView from '../../../js/base-view';
import fixHoverState from '../../../js/utils/fix-hover-state';
import getChildrenMatches from '../../../js/utils/getChildrenMatches';
import getRecursiveChildrenMatches from '../../../js/utils/getRecursiveChildrenMatches';

import NavScrollhandler from './navigation-scroll';

// hack to avoid treeshaking of gsap plugins
// eslint-disable-next-line no-unused-vars
const plugins = [ CSSPlugin, Power2 ];

// deps settings
// CSSPlugin.defaultForce3D = true;

/* config */
const SELECTOR_NAV_INNER = '.nav__inner';
const SELECTOR_NAV_BACKDROP_TOP = '.nav__backdrop-top';
const SELECTOR_NAV_BACKDROP_MAIN = '.nav__backdrop-main';
const SELECTOR_BTN_MENU_TOGGLE = '.nav__mobile_button';
const SELECTOR_ANY_OPEN_CONTROL = '[aria-expanded="true"]';
const SELECTOR_CONTROL = '[aria-controls][aria-haspopup="true"]'; // TODO: add data attribute instead?
const SELECTOR_ANY_MENUITEM_WRAPPER = '.submenu__item,.menu__item';
const SELECTOR_ANY_MENUITEM = '[role="menuitem"]';
const SELECTOR_TOPMENU_ITEM = '.menu__item-link';
const SELECTOR_TOPMENU_UNDERLINE = '.menu__underline';
const SELECTOR_SUBMENU = '.submenu';
// const SELECTOR_SUBMENU_LINK = '.submenu__link';
// const SELECTOR_SUBMENU_ITEM = '.submenu__more';
const SELECTOR_SUBMENU_BACK = '.submenu__back';
const SELECTOR_SUBMENU_CLOSE = '[data-navigation-close]';
const SELECTOR_SUBMENU_BACKGROUND = '.submenu-background';
const SELECTORS_SUBMENU_ITEMS_TO_HIDE = [
	// used to get direct children (please keep the ` > ` structure!)
	'.submenu__inner > .submenu__inner__content > .submenu__back',
	'.submenu__inner > .submenu__inner__content > .submenu__list > .submenu__item > .submenu__link',
	'.submenu__inner > .submenu__inner__content > .submenu__list > .submenu__item > .submenu__more',
];
const SELECTOR_CURRENT_POSTID = '[id^="post-"]'; // to get the post id from the page's dom
const SELECTOR_LANGUAGE_WRAPPER = '.nav__languages';
const CLASS_MENUITEM_CURRENT = 'is-current';
const CLASS_MENUITEM_CURRENT_PARENT = 'is-current-parent';
const CLASS_MENUITEM_CURRENT_ANCESTOR = 'is-current-ancestor';
const MOBILE_MENU_TRANSLATE_PERCENTAGE = 0.2;
const BREAKPOINT_DESKTOP_MODE = 1024; // menu should be in desktop version when >= …px
const UNDERLINE_DELAY = 0.3;

// in px, to match with scss var $menu-height-desktop-default
const MENU_HEIGHT_DESKTOP_DEFAULT = 128;

export default class Navigation extends BaseView {
	// #region Initialisation

	/**
	 * Entrypoint function of the component, called on initialisation
	 * See BaseView for more understanding
	 */
	bind() {
		// component state
		// NOTE: keep it 1-level deep only (flat object), else you have
		//       to use deep cloning, deep merging and deep comparison
		this.state = {
			elCurrentSubmenu: null,
			isMobile: undefined,
			isMobileOpen: false,
		};

		// cache
		// - gets updated on state change
		// - we use it to avoid accessing the dom too much
		this.cache = {
			level: -1,
			elCurrentRootSubmenu: null,
			elCurrentSubmenuControl: null,
			menubarHeight: 0,
			tl: new TimelineLite(), // used to be able to kill running timeline
			tlUnderline: new TimelineLite(), // used for underline animation
			isUnderlineInit: false,
			isUnderlineActive: false,
		};

		// dom elements
		this.el = {
			// decoration elements
			navInner: this.getScopedElement( SELECTOR_NAV_INNER ),
			navBackdropTop: this.getScopedElement( SELECTOR_NAV_BACKDROP_TOP ),
			navBackdropMain: this.getScopedElement( SELECTOR_NAV_BACKDROP_MAIN ),
			submenuBackground: this.getScopedElement( SELECTOR_SUBMENU_BACKGROUND ),

			// navigation elements
			btnMenuToggle: this.getScopedElement( SELECTOR_BTN_MENU_TOGGLE ),
			menuUnderline: this.getScopedElement( SELECTOR_TOPMENU_UNDERLINE ),
			submenuBack: this.getScopedElements( SELECTOR_SUBMENU_BACK ),
			submenuClose: this.getScopedElements( SELECTOR_SUBMENU_CLOSE ),
			languagesWrap: this.getScopedElement( SELECTOR_LANGUAGE_WRAPPER ),
		};

		// props extracted from the dom
		this.props = {
			mobileBtnOpenLabel: this.el.btnMenuToggle.innerText,
			mobileBtnCloseLabel: this.el.btnMenuToggle.getAttribute( 'data-close-label' ),
		};

		// adapt menu based on scroll progress
		this.scrollHandler = NavScrollhandler( this );

		// initialize UI
		this.updateMenuMode();
		this.updateCachedSizes();
		this.bindEventListeners();
	}

	/**
	 * Bind all event listeners
	 */
	bindEventListeners() {
		// viewport
		viewport_service.on( 'change', () => this.handleViewportResize() );

		// mobile menu toggle
		this.on( 'click', SELECTOR_BTN_MENU_TOGGLE, ( ev ) => this.handleBtnMenuToggleClick( ev ) );

		// navigation
		this.on( 'click', SELECTOR_CONTROL, ( ev ) => this.handleMenuControlClick( ev ) );
		this.on( 'click', SELECTOR_SUBMENU_BACK, ( ev ) => this.handleSubMenuBackClick( ev ) );
		this.on( 'click', SELECTOR_NAV_BACKDROP_MAIN, ( ev ) => this.handleBackdropClick( ev ) );

		// page change transition
		this.on( 'barba:start', ( ev ) => this.handleBeforePageChange( ev.detail.callback ), true );
		this.on( 'barba:finish', ( ev ) => this.handlePageLoaded( ev.detail.callback ), true );

		// hover effect on top-menu
		this.on( 'mouseenter', SELECTOR_TOPMENU_ITEM, ( ev ) => this.handleMenuItemMouseenter( ev ) );
		this.on( 'mouseleave', SELECTOR_TOPMENU_ITEM, ( ev ) => this.handleMenuItemMouseleave( ev ) );
	}

	// #endregion

	// #region State Management

	/**
	 * Get the current state object
	 *
	 * @return {Object} a shallow copy of the state object
	 */
	getState() {
		return { ...this.state }; // copy the object to avoid mutations
	}

	/**
	 * Get the current cache object
	 *
	 * @return {Object} a shallow copy of the cache object
	 */
	getCache() {
		return { ...this.cache }; // copy the object to avoid mutations
	}

	/**
	 * Update the state
	 * NOTE: any state change expected to properly update the UI should go through this method
	 *
	 * @param {Object} overrides An object with the key-value pairs to replace in the state
	 */
	setState( overrides = {} ) {
		// TODO: refactor to have a very dumb state management. Have here only the merge + save
		// of objects, move actions to methods that update the state themselves with setState(),
		// like `mobileToggleMenu`, `setSubmenu`,…

		// vars
		const oldState = this.getState();
		const newState = { ...oldState, ...overrides };
		const oldCache = this.getCache();
		const newCache = { ...oldCache };

		// for debug
		// console.log('state changed', oldState, newState);

		// bail early if no state change
		if ( newState === oldState ) {
			return;
		}

		// mobile: open/closed changed?
		if ( newState.isMobile && oldState.isMobileOpen !== newState.isMobileOpen ) {
			if ( newState.isMobileOpen ) {
				this.mobileOpenMenu();
			}
			else {
				newState.elCurrentSubmenu = null;
				this.mobileCloseMenu();
			}
		}

		// submenu changed?
		else if ( oldState.elCurrentSubmenu !== newState.elCurrentSubmenu ) {
			// vars
			const wasAnySubmenuOpen = !! oldState.elCurrentSubmenu;
			const isAnySubmenuOpen = !! newState.elCurrentSubmenu;

			// update cache
			newCache.elCurrentSubmenuControl = isAnySubmenuOpen
				? this.getSubmenuControl( newState.elCurrentSubmenu )
				: null;
			newCache.level = isAnySubmenuOpen
				? this.getLevel( newCache.elCurrentSubmenuControl )
				: -1;
			if ( newCache.level === 0 ) {
				// switched to a root submenu -> update cache
				newCache.elCurrentRootSubmenu = newState.elCurrentSubmenu;
			}
			if ( newCache.level === -1 ) {
				// closed menu, meaning no root submenu -> update cache
				newCache.elCurrentRootSubmenu = null;
			}

			// update UI
			// -- mobile
			if ( newState.isMobile ) {
				// go deeper
				if ( oldCache.level < newCache.level ) {
					this.mobileGotoChildSubmenu(
						oldState.elCurrentSubmenu,
						newState.elCurrentSubmenu,
						newCache.elCurrentSubmenuControl,
					);
				}
				// go back
				else if ( oldCache.level > newCache.level ) {
					this.mobileGotoParentSubmenu(
						oldState.elCurrentSubmenu,
						oldCache.elCurrentSubmenuControl,
						newState.elCurrentSubmenu,
						newCache.elCurrentSubmenuControl,
					);
				}
			}
			// -- desktop
			else {
				// eslint-disable-next-line no-lonely-if
				if ( ! isAnySubmenuOpen ) {
					// open -> closed
					this.closeSubmenu(
						oldState.elCurrentSubmenu,
						oldCache.elCurrentSubmenuControl,
					);
				}
				else if ( ! wasAnySubmenuOpen ) {
					// closed -> open
					this.openSubmenu(
						newState.elCurrentSubmenu,
						newCache.elCurrentSubmenuControl,
					);
				}
				else if (
					// open a root submenu, coming from another submenu
					newCache.level === 0 &&
					newCache.elCurrentRootSubmenu !== oldCache.elCurrentRootSubmenu
				) {
					// switch to sibling submenu
					this.gotoSiblingSubmenu(
						oldState.elCurrentSubmenu,
						oldCache.elCurrentSubmenuControl,
						newState.elCurrentSubmenu,
						newCache.elCurrentSubmenuControl,
					);
				}
				else if ( oldCache.level < newCache.level ) {
					// go deeper
					this.gotoChildSubmenu(
						oldState.elCurrentSubmenu,
						newState.elCurrentSubmenu,
						newCache.elCurrentSubmenuControl,
					);
				}
				else if ( oldCache.level > newCache.level ) {
					// go back
					this.gotoParentSubmenu(
						oldState.elCurrentSubmenu,
						oldCache.elCurrentSubmenuControl,
						newState.elCurrentSubmenu,
						newCache.elCurrentSubmenuControl,
					);
				}
			}
		}

		// navigation mode changed? (mobile/desktop)
		if ( oldState.isMobile !== newState.isMobile ) {
			// reset UI & state
			newState.elCurrentSubmenu = null;
			newCache.elCurrentSubmenuControl = null;
			newCache.level = -1;
			this.resetUI();

			// switch mode
			if ( newState.isMobile ) {
				this.setMobileMode();
			}
			else {
				this.setDesktopMode();
			}
		}

		// update state & cache
		this.state = newState;
		this.cache = newCache;
	}

	// #endregion

	// #region Event Handlers

	/**
	 * When screen size changes
	 */
	handleViewportResize() {
		this.updateMenuMode();
		this.updateCachedSizes();
		this.scrollHandler.update();
		// TODO: update cached menu height size on scrollHandler update?
	}

	/**
	 * When user clicks on mobile "open/close menu" button
	 *
	 * @param {Event} ev Original event
	 */
	handleBtnMenuToggleClick( ev ) {
		ev.preventDefault();

		// open/close menu
		this.setState( {
			isMobileOpen: ! this.getState().isMobileOpen,
		} );
	}

	/**
	 * When user clicks a control (= a link/button that should open a submenu)
	 *
	 * @param {Event} ev Original event
	 */
	handleMenuControlClick( ev ) {
		const { target } = ev;

		if ( this.getLevel( target ) === 0 && target.getAttribute( 'aria-haspopup' ) !== 'true' ) {
			return;
		}
		ev.preventDefault();

		const { elCurrentSubmenu } = this.getState();
		const submenuId = target.getAttribute( 'aria-controls' );
		let elSubmenu = this.getSubmenuFromId( submenuId );

		// top-level: if submenu or child is currently open, close it
		if (
			this.getLevel( target ) === 0 && // control is in the top-level menu
			elCurrentSubmenu && // a submenu is open (non-null)
			(
				elSubmenu === elCurrentSubmenu || // the submenu is open
				elSubmenu.querySelectorAll( `#${ elCurrentSubmenu.id }` ).length // a child of the submenu is open
			)
		) {
			elSubmenu = null;
		}

		// update state
		this.setState( {
			elCurrentSubmenu: elSubmenu,
		} );
	}

	/**
	 * When user clicks the "back" link in a submenu
	 *
	 * @param {Event} ev Original event
	 */
	handleSubMenuBackClick( ev ) {
		// vars
		const elTarget = ev.target;
		const elSubmenu = elTarget
			.parentElement.closest( SELECTOR_SUBMENU )
			.parentElement.closest( SELECTOR_SUBMENU );

		// prevent default event
		ev.preventDefault();

		// update state
		this.setState( {
			elCurrentSubmenu: elSubmenu,
		} );
	}

	/**
	 * When user clicks the backdrop
	 *
	 * @param {Event} ev Original event
	 */
	handleBackdropClick( ev ) {
		ev.preventDefault();

		// close submenus
		this.setState( {
			elCurrentSubmenu: null,
		} );
	}

	/**
	 * When user hovers a top-menu item
	 *
	 * @param {Event} ev Original event
	 */
	handleMenuItemMouseenter( ev ) {
		if ( this.getState().isMobile ) {
			return;
		}

		this.menuUnderlineMoveTo( ev.target );
	}

	/**
	 * When user stops hovering a top-menu item
	 *
	 * @param {Event} ev Original event
	 */
	handleMenuItemMouseleave() {
		if ( this.getState().isMobile ) {
			return;
		}

		this.menuUnderlineReset();
	}

	/**
	 * Before leaving the page (start the animation)
	 *
	 * @param {Function} cb callback to execute when transition finished
	 */
	handleBeforePageChange( cb ) {
		// TODO: refactor, move in an "action" method
		this.updateCachedSizes();

		const animate = true;
		const elSubmenu = this.state.elCurrentSubmenu;

		// vars
		const viewportHeight = viewport_service.getCurrentHeight();
		const speed = animate ? 1 : 0;
		const speedify = ( seconds ) => speed * seconds;
		const elsSubmenuItems = elSubmenu ? this.getSubmenuItemsToHide( elSubmenu ) : [];

		// animate closing
		this.cache.tl.stop()
			.seek( this.cache.tl.duration() ).kill(); // kill previous running animations
		this.cache.tl = new TimelineLite();

		// cover screen
		if ( this.state.isMobile ) {
			// update control (open/close)
			this.el.btnMenuToggle.children[ 0 ].innerHTML = this.props.mobileBtnOpenLabel;
			this.el.btnMenuToggle.setAttribute( 'aria-expanded', 'false' );

			this.cache.tl
				// - set defaults
				.set( this.el.navBackdropMain, {
					css: {
						scaleY: 1,
						y: viewportHeight,
						opacity: 1,
					},
				} )

				// - cover the screen with white backdrop
				.to( this.el.navBackdropMain, speedify( 0.5 ), {
					css: {
						y: 0,
					},
				} )

				// - screen covered; hide the things!
				.set( this.el.navInner, {
					css: {
						opacity: 0,
						pointerEvents: 'none',
					},
				} );
		}
		else {
			// if submenu open
			if ( elSubmenu ) {
				this.cache.tl
					// - set initial values
					.set( this.el.navBackdropTop, {
						css: {
							zIndex: 30,
							y: 0,
							transformOrigin: '50% 0%',
						},
					} )
					.set( this.el.navBackdropMain, {
						css: {
							scale: 1,
							transformOrigin: '50% 0%',
						},
					} )
					.set( [ elSubmenu, elsSubmenuItems ], {
						css: {
							pointerEvents: 'none',
						},
					} )
					// - cover the screen with green backdrop (main)
					.to( this.el.navBackdropMain, speedify( 0.15 ), {
						css: {
							opacity: 1,
						},
						ease: Power2.easeIn,
					} )
					.to( this.el.navBackdropMain, speedify( 0.5 ), {
						css: {
							y: 0, transformOrigin: '50% 0%',
						},
						ease: Power2.easeIn,
					} )
					// screen covered; hide the things!
					.set( this.el.submenuBackground, {
						css: {
							scaleY: 0, transformOrigin: '50% 0%',
						},
					} );
			}
			// if submenu closed
			else {
				this.cache.tl
					// - set initial values
					.set( this.el.navBackdropTop, {
						css: {
							zIndex: 5,
						},
					} )
					.set( this.el.navBackdropMain, {
						css: {
							scaleY: 0.5, // arbitrary size to look like the submenu opening animation
							transformOrigin: '50% 0%',
							y: -0.5 * viewportHeight,
							opacity: 1,
						},
					} )
					// - cover the screen with green backdrop (main)
					.to( this.el.navBackdropMain, speedify( 0.6 ), {
						css: {
							y: 0, transformOrigin: '50% 0%',
						},
						ease: Power2.easeIn,
					} )
					// - screen covered; add backdrop top
					.set( this.el.navBackdropTop, {
						css: {
							y: 0,
							transformOrigin: '50% 0%',
						},
					} )
					// - move the backdrop down to cover the screen (except menu bar)
					.to( this.el.navBackdropMain, speedify( 0.4 ), {
						css: {
							y: MENU_HEIGHT_DESKTOP_DEFAULT,
							transformOrigin: '50% 0%',
						},
						ease: Power2.easeOut,
					} )
					.to( this.el.navBackdropMain, speedify( 0.4 ), {
						css: {
							scaleY: ( viewportHeight - MENU_HEIGHT_DESKTOP_DEFAULT ) / viewportHeight,
							transformOrigin: '50% 0%',
						},
						ease: Power2.easeOut,
					}, `-=${ speedify( 0.4 ) }` );
			}

			if ( elSubmenu ) {
				this.cache.tl
					.set( elSubmenu, {
						css: {
							opacity: 0,
						},
					} );
			}
		}

		// reset menu if open
		if ( elSubmenu ) {
			this.cache.tl.call( () => {
				this.resetUI( this.cache.elCurrentRootSubmenu );
			} );
		}

		// update state & cache (TODO: refactor!)
		this.state.elCurrentSubmenu = null;
		this.state.isMobileOpen = false;
		this.cache.level = -1;
		this.cache.elCurrentRootSubmenu = null;
		this.cache.elCurrentSubmenuControl = null;

		// call callback
		this.cache.tl.call( cb );
	}

	/**
	 * When new page is loaded
	 *
	 * @param {Function} cb callback to execute when transition finished
	 */
	handlePageLoaded( cb ) {
		// TODO: refactor, move in an "action" method
		this.updateCachedSizes();

		// vars
		const animate = true;
		const viewportHeight = viewport_service.getCurrentHeight();
		const speed = animate ? 1 : 0;
		const speedify = ( seconds ) => speed * seconds;

		// animate revealing
		// this.cache.tl.stop().seek(this.cache.tl.duration()).kill(); // kill running animations
		// this.cache.tl = new TimelineLite();

		if ( this.state.isMobile ) {
			this.cache.tl
				// - uncover the screen
				.to( this.el.navBackdropMain, speedify( 0.5 ), {
					css: {
						y: -1 * viewportHeight,
					},
				} );
		}
		else {
			this.cache.tl
				// - reveal the page
				.set( this.el.navBackdropTop, {
					css: {
						zIndex: 30,
					},
				} )
				.to( this.el.navBackdropMain, speedify( 0.6 ), {
					css: {
						y: -1 * viewportHeight, transformOrigin: '50% 0%',
					},
					ease: Power2.easeIn,
				} )
				.to( this.el.navBackdropTop, speedify( 0.2 ), {
					css: {
						y: -1 * this.cache.menubarHeight,
						transformOrigin: '50% 0%',
					},
				} );
		}

		// update nav UI state
		this.cache.tl.call( () => {
			this.updateCurrentPageItems();
			this.menuUnderlineReset();

			this.resetUI();

			if ( window.supt && window.supt.pageupdate ) {
				this.element.className = window.supt.pageupdate.navClass;
				this.updateLanguageSelector( window.supt.pageupdate.langs );
			}
		} );

		// call callback
		this.cache.tl.call( cb, [], this, `-=${ speedify( 0.35 ) }` ); // adjustment for the header animation
	}

	// #endregion

	// #region Helpers: Responsive

	/**
	 * Set the menu to mobile/desktop mode
	 * depending on viewport size
	 */
	updateMenuMode() {
		this.setState( {
			isMobile: this.isMobile(),
		} );
	}

	/**
	 * Is viewport mobile?
	 */
	isMobile() {
		return viewport_service.getCurrentWidth() < BREAKPOINT_DESKTOP_MODE;
	}

	/**
	 * Update cached dom elements sizes
	 */
	updateCachedSizes() {
		this.cache.menubarHeight = this.el.navInner.offsetHeight;
	}

	/**
	 * Switch to mobile mode
	 */
	setMobileMode() {}

	/**
	 * Switch to desktop mode
	 */
	setDesktopMode() {
		this.el.btnMenuToggle.children[ 0 ].innerHTML = this.props.mobileBtnOpenLabel;
		this.menuUnderlineReset( false );
	}

	// #endregion

	// #region Helpers: Top-menu underline

	menuUnderlineMoveTo( newEl, delay = 0 ) {
		// vars
		const speedMove = this.getCache().isUnderlineActive ? 1 : 0; // animate only if already active
		const speedScale = this.getCache().isUnderlineInit ? 1 : 0; // animate only if initialized
		const underlineTranslateX = newEl.offsetLeft - newEl.parentElement.parentElement.offsetLeft;
		const underlineScaleX = newEl.offsetWidth / this.el.menuUnderline.offsetWidth;

		// update cache
		this.cache.isUnderlineActive = true;
		this.cache.isUnderlineInit = true;

		// animate
		this.cache.tlUnderline.kill().clear()
			.to( this.el.menuUnderline, 0.3 * speedMove, {
				css: {
					x: underlineTranslateX,
					transformOrigin: '0% 0%',
				},
				delay,
			}, 0 )
			.to( this.el.menuUnderline, 0.3 * speedScale, {
				css: {
					scaleX: underlineScaleX,
					transformOrigin: '0% 0%',
				},
				delay,
			}, 0 )
			.to( this.el.menuUnderline, 0.3, {
				css: {
					opacity: 1,
				},
				delay,
			}, 0 );
	}

	menuUnderlineReset( animate = true ) {
		// vars
		const speed = animate ? 1 : 0;
		const speedify = ( seconds ) => speed * seconds;
		const { elCurrentRootSubmenu } = this.getCache();
		const newEl = elCurrentRootSubmenu
			? this.getSubmenuControl( elCurrentRootSubmenu )
			: this.getCurrentTopMenuItem();

		// if there's an active item to go to: move
		if ( newEl ) {
			this.menuUnderlineMoveTo( newEl, UNDERLINE_DELAY );
			return;
		}

		// else: hide
		this.cache.tlUnderline.kill().clear()
			.set( this.el.menuUnderline, {
				css: {
					opacity: 1,
				},
			} )
			.to( this.el.menuUnderline, speedify( 0.3 ), {
				css: {
					scaleX: 0,
					transformOrigin: '0% 0%',
				},
				delay: speedify( UNDERLINE_DELAY ),
			}, 0 )
			.call( () => {
				this.cache.isUnderlineActive = false;
			} );

		// update cache
		this.cache.isUnderlineInit = true;
	}

	// #endregion

	// #region Helpers: DOM

	/**
	 * Get the top-menu item of the page currently open
	 */
	getCurrentTopMenuItem() {
		return this.getScopedElement(
			[
				`${ SELECTOR_TOPMENU_ITEM }.${ CLASS_MENUITEM_CURRENT }`,
				`${ SELECTOR_TOPMENU_ITEM }.${ CLASS_MENUITEM_CURRENT_PARENT }`,
				`${ SELECTOR_TOPMENU_ITEM }.${ CLASS_MENUITEM_CURRENT_ANCESTOR }`,
			].join( ',' ),
		);
	}

	/**
	 * Get the control of a given submenu
	 *
	 * @param {Node} elSubmenu The submenu (must have a unique id)
	 * @return {Element|null} The control | null if not found
	 */
	getSubmenuControl( elSubmenu ) {
		return this.getScopedElement(
			`${ SELECTOR_CONTROL }[aria-controls="${ elSubmenu.id }"]`,
		);
	}

	/**
	 * Get the 0-based menu level of a control element
	 *
	 * @param {Node} elControl The control element that has `[data-level]`
	 * @return {number} An int representing the 0-based menu level
	 */
	getLevel( elControl ) {
		return parseInt( elControl.getAttribute( 'data-level' ) );
	}

	/**
	 * Get submenu for the given html id
	 *
	 * @param {string} id Unique ID of the submenu
	 * @return {Element|null} The submenu | null if not found or invalid id
	 */
	getSubmenuFromId( id ) {
		// bail early if non-valid id
		if (
			typeof id !== 'string' ||
			! id.length
		) {
			return null;
		}

		return this.getScopedElement( `#${ id }` );
	}

	/**
	 * Get all nodes we need to hide on a submenu when showing its child submenu
	 *
	 * @param {Node} elSubmenu the submenu to search in
	 */
	getSubmenuItemsToHide( elSubmenu ) {
		// vars
		const els = [];

		// get all dom elements to hide
		SELECTORS_SUBMENU_ITEMS_TO_HIDE.forEach( ( selectors ) => els.push(
			...getRecursiveChildrenMatches( [ elSubmenu ], selectors.split( ' > ' ) ),
		) );

		// return
		return els;
	}

	// #endregion

	// #region Helpers: Navigation

	/**
	 * Mobile: Open the hamburger menu
	 *
	 * @param {boolean} animate should we animate?
	 */
	mobileOpenMenu( animate = true ) {
		// To update the height of the menu bar
		this.updateCachedSizes();

		// vars
		const elControl = this.el.btnMenuToggle;
		const viewportHeight = viewport_service.getCurrentHeight();
		const speed = animate ? 1 : 0;
		const speedify = ( seconds ) => speed * seconds;

		// update control (open/close)
		elControl.children[ 0 ].innerHTML = this.props.mobileBtnCloseLabel;
		elControl.setAttribute( 'aria-expanded', 'true' );

		// Disable the body scroll below the menu
		// disableBodyScroll(this.el.navInner);

		// animate opening
		this.cache.tl.stop()
			.seek( this.cache.tl.duration() ).kill(); // kill previous running animations
		this.cache.tl = new TimelineLite()

			// - set defaults
			.set( this.el.navBackdropMain, {
				css: {
					scaleY: 1,
					y: -1 * viewportHeight,
					opacity: 1,
				},
			} )

			// - cover the screen with white backdrop
			.to( this.el.navBackdropMain, speedify( 0.5 ), {
				css: {
					y: 0,
				},
			} )

			// - screen covered; show the things!
			.set( this.el.navInner, {
				css: {
					opacity: 1,
					pointerEvents: 'auto',
				},
			} )

			// - uncover the screen
			.to( this.el.navBackdropMain, speedify( 0.5 ), {
				css: {
					y: viewportHeight,
				},
			} )

			// - reset hover state to make sure it plays again on next touch
			.call(
				() => setTimeout( () => {
					fixHoverState( this.el.btnMenuToggle );
				}, 400 ),
			);
	}

	/**
	 * Mobile: Close the hamburger menu
	 *
	 * @param {boolean} animate should we animate?
	 */
	mobileCloseMenu( animate = true ) {
		// vars
		const elControl = this.el.btnMenuToggle;
		const viewportHeight = viewport_service.getCurrentHeight();
		const speed = animate ? 1 : 0;
		const speedify = ( seconds ) => speed * seconds;

		// update control (open/close)
		elControl.children[ 0 ].innerHTML = this.props.mobileBtnOpenLabel;
		elControl.setAttribute( 'aria-expanded', 'false' );

		// Enable back the scroll on the body
		// clearAllBodyScrollLocks();

		// animate closing
		this.cache.tl.stop()
			.seek( this.cache.tl.duration() ).kill(); // kill previous running animations
		this.cache.tl = new TimelineLite()

			// - set defaults
			.set( this.el.navBackdropMain, {
				css: {
					scaleY: 1,
					y: viewportHeight,
					opacity: 1,
				},
			} )

			// - cover the screen with white backdrop
			.to( this.el.navBackdropMain, speedify( 0.5 ), {
				css: {
					y: 0,
				},
			} )

			// - screen covered; hide the things!
			.set( this.el.navInner, {
				css: {
					opacity: 0,
					pointerEvents: 'none',
				},
			} )

			// - uncover the screen
			.to( this.el.navBackdropMain, speedify( 0.5 ), {
				css: {
					y: -1 * viewportHeight,
				},
			} )

			// - reset hover state to make sure it plays again on next touch
			.call(
				() => setTimeout( () => {
					fixHoverState( this.el.btnMenuToggle );
				}, 400 ),
			)

			// - reset UI to open the 1st-level submenu next time
			.call( () => {
				this.resetUI();
			} );
	}

	/**
	 * Closed submenu -> open submenu
	 *
	 * @param {Node} elSubmenu the submenu
	 * @param {Node} elControl the control that was clicked to open the submenu (= aria-controls)
	 * @param {boolean} animate should we animate?
	 */
	openSubmenu( elSubmenu, elControl, animate = true ) {
		// update control item
		elControl.setAttribute( 'aria-expanded', 'true' );
		// elControl.setAttribute('data-nav-current', 'true');

		// To update the height of the menu bar
		this.updateCachedSizes();

		// vars
		const speed = animate ? 1 : 0;
		const speedify = ( seconds ) => speed * seconds;
		const viewportHeight = viewport_service.getCurrentHeight();
		const submenuHeight = elSubmenu.offsetHeight;
		const backdropFromHeight = (
			this.cache.menubarHeight +
			submenuHeight +
			100 // arbitrary margin
		);
		const backdropToY = this.cache.menubarHeight + submenuHeight;
		const backdropToScaleY = (
			viewportHeight -
			this.cache.menubarHeight -
			submenuHeight
		) / viewportHeight;
		const submenuScale = submenuHeight / this.el.submenuBackground.offsetHeight;
		const elsSubmenuItems = this.getSubmenuItemsToHide( elSubmenu );

		// Disable the body scroll below the menu
		disableBodyScroll( this.el.navInner );

		// animate opening
		this.cache.tl.stop()
			.seek( this.cache.tl.duration() ).kill(); // kill previous running animations
		this.cache.tl = new TimelineLite()

			// - set initial values
			.set( this.el.navBackdropTop, {
				css: {
					zIndex: 5,
				},
			} )
			.set( this.el.navBackdropMain, {
				css: {
					scaleY: backdropFromHeight / viewportHeight,
					transformOrigin: '50% 0%',
					y: -1 * backdropFromHeight,
				},
			} )

			// - cover the screen with green backdrop (main)
			.to( this.el.navBackdropMain, speedify( 0.5 ), {
				css: {
					y: 0, transformOrigin: '50% 0%',
				},
				ease: Power2.easeIn,
			} )

			// - screen covered; show the things!
			.set( this.el.navBackdropTop, {
				css: {
					y: 0,
					transformOrigin: '50% 0%',
				},
			} )
			.set( this.el.submenuBackground, {
				css: {
					scaleY: submenuScale, transformOrigin: '50% 0%',
				},
			} )

			// - reveal the submenu
			.to( this.el.navBackdropMain, speedify( 0.7 ), {
				css: {
					y: backdropToY, transformOrigin: '50% 0%',
				},
				ease: Power2.easeOut,
			} )
			.add( 'startSubmenuReveal', `-=${ speedify( 0.4 ) }` ) // position label
			.to( this.el.navBackdropMain, speedify( 0.7 ), {
				css: {
					scaleY: backdropToScaleY, transformOrigin: '50% 0%',
				},
				ease: Power2.easeOut,
			}, `-=${ speedify( 0.4 ) }` )
			.to( this.el.navBackdropMain, speedify( 0.3 ), {
				css: {
					opacity: 0.9,
				},
				ease: Power2.easeIn,
			}, `-=${ speedify( 0.3 ) }` )
			//   set to full height to avoid "glitches" on resize
			//   (when we grow the viewport height, the backdrop
			//   would not be tall enough, before it is updated)
			.set( this.el.navBackdropMain, {
				css: {
					scaleY: 1, transformOrigin: '50% 0%',
				},
			} )

			// - submenu
			.to( elSubmenu, speedify( 1 ), {
				css: {
					opacity: 1,
				},
			}, 'startSubmenuReveal' )
			.set( [ elSubmenu, elsSubmenuItems ], {
				css: {
					pointerEvents: 'auto',
				},
			} );
	}

	/**
	 * Open submenu -> closed submenu
	 *
	 * @param {Node} elSubmenu the submenu to close
	 * @param {Node} elControl the control that was clicked to close the submenu (= aria-controls)
	 * @param {boolean} animate should we animate?
	 */
	closeSubmenu( elSubmenu, elControl, animate = true ) {
		// update control item
		elControl.setAttribute( 'aria-expanded', 'false' );
		// elControl.removeAttribute('data-nav-current');

		// vars
		const viewportHeight = viewport_service.getCurrentHeight();
		const speed = animate ? 1 : 0;
		const speedify = ( seconds ) => speed * seconds;
		const elsSubmenuItems = this.getSubmenuItemsToHide( elSubmenu );

		// Enable back the scroll on the body
		enableBodyScroll( this.el.navInner );

		// animate closing
		this.cache.tl.stop()
			.seek( this.cache.tl.duration() ).kill(); // kill previous running animations
		this.cache.tl = new TimelineLite()

			// - set initial values
			.set( this.el.navBackdropTop, {
				css: {
					zIndex: 30,
				},
			} )
			.set( [ elSubmenu, elsSubmenuItems ], {
				css: {
					pointerEvents: 'none',
				},
			} )
			.set( this.el.navBackdropMain, {
				css: {
					scale: 1, transformOrigin: '50% 0%',
				},
			} )

			// - cover the screen with green backdrop (main)
			.to( this.el.navBackdropMain, speedify( 0.15 ), {
				css: {
					opacity: 1,
				},
				ease: Power2.easeIn,
			} )
			.to( this.el.navBackdropMain, speedify( 0.5 ), {
				css: {
					y: 0, transformOrigin: '50% 0%',
				},
				ease: Power2.easeIn,
			} )

			// - screen covered; hide the things
			.set( this.el.submenuBackground, {
				css: {
					scaleY: 0, transformOrigin: '50% 0%',
				},
			} )
			.set( elSubmenu, {
				css: {
					opacity: 0,
				},
			} )
			// TODO: split animation here for page transition!

			// - things hidden; reveal the page
			.to( this.el.navBackdropMain, speedify( 0.5 ), {
				css: {
					y: -1 * viewportHeight, transformOrigin: '50% 0%',
				},
				ease: Power2.easeIn,
			} )
			.to( this.el.navBackdropTop, speedify( 0.2 ), {
				css: {
					y: -1 * this.cache.menubarHeight,
					transformOrigin: '50% 0%',
				},
			} )

			// - reset UI to open the 1st-level submenu next time
			.call( () => {
				this.resetUI();
				this.menuUnderlineReset();
			} );
	}

	/**
	 * Open a sibling 1st-level submenu
	 *
	 * @param {Node} elOldSubmenu the submenu to close
	 * @param {Node} elOldControl the control of the submenu to close
	 * @param {Node} elNewSubmenu the submenu to open
	 * @param {Node} elNewControl the control of the submenu to open
	 * @param {boolean} animate should we animate?
	 */
	gotoSiblingSubmenu( elOldSubmenu, elOldControl, elNewSubmenu, elNewControl, animate = true ) {
		// update control items
		elOldControl.setAttribute( 'aria-expanded', 'false' );
		// elOldControl.removeAttribute('data-nav-current');
		elNewControl.setAttribute( 'aria-expanded', 'true' );
		// elNewControl.setAttribute('data-nav-current', 'true');

		// vars
		const speed = animate ? 1 : 0;
		const speedify = ( seconds ) => speed * seconds;
		const submenuHeight = elNewSubmenu.offsetHeight;
		const backdropToY = this.cache.menubarHeight + submenuHeight;
		const submenuScale = submenuHeight / this.el.submenuBackground.offsetHeight;

		// animate switching
		this.cache.tl.stop()
			.seek( this.cache.tl.duration() ).kill(); // kill previous running animations
		this.cache.tl = new TimelineLite()

			// - hide old submenu
			.to( elOldSubmenu, speedify( 0.3 ), {
				css: {
					opacity: 0,
				},
			} )
			.set( elOldSubmenu, {
				css: {
					pointerEvents: 'none',
				},
			} )

			// - show new submenu
			.to( elNewSubmenu, speedify( 0.3 ), {
				css: {
					opacity: 1,
				},
			}, `-=${ speedify( 0.1 ) }` )
			.set( elNewSubmenu, {
				css: {
					pointerEvents: 'auto',
				},
			} )

			// - scale background properly (+ move backdrop)
			.to( this.el.submenuBackground, speedify( 0.3 ), {
				css: {
					scaleY: submenuScale, transformOrigin: '50% 0%',
				},
			}, speedify( 0.2 ) )
			.to( this.el.navBackdropMain, speedify( 0.3 ), {
				css: {
					y: backdropToY, transformOrigin: '50% 0%',
				},
			}, speedify( 0.2 ) )

			// - reset other submenus, to go back to the root when we switch back to them
			.call( () => {
				this.getScopedElements( `${ SELECTOR_CONTROL }[data-level="0"]` ).forEach( ( elControl ) => {
					// bail early if current control
					if ( elControl === elNewControl ) {
						return;
					}

					// reset UI of the submenu
					const elSubmenu = this.getSubmenuFromId( elControl.getAttribute( 'aria-controls' ) );
					this.resetUI( elSubmenu );
					elControl.setAttribute( 'aria-expanded', 'false' );
				} );
			} );
	}

	/**
	 * Open a child submenu (level 1+)
	 *
	 * @param {Node} elOldSubmenu the previous submenu (= parent)
	 * @param {Node} elNewSubmenu the submenu to open (= child)
	 * @param {Node} elNewControl the control of the submenu to open
	 * @param {boolean} animate should we animate?
	 */
	gotoChildSubmenu( elOldSubmenu, elNewSubmenu, elNewControl, animate = true ) {
		// update control items
		// elOldControl.removeAttribute('data-nav-current');
		elNewControl.setAttribute( 'aria-expanded', 'true' );
		// elNewControl.setAttribute('data-nav-current', 'true');

		// vars
		const speed = animate ? 1 : 0;
		const speedify = ( seconds ) => speed * seconds;
		const elsOldSubmenuItems = this.getSubmenuItemsToHide( elOldSubmenu );
		const elsNewSubmenuItems = this.getSubmenuItemsToHide( elNewSubmenu );
		const submenuHeight = elNewSubmenu.offsetHeight;
		const backdropToY = this.cache.menubarHeight + submenuHeight;
		const submenuScale = submenuHeight / this.el.submenuBackground.offsetHeight;

		// animate switching
		this.cache.tl.stop()
			.seek( this.cache.tl.duration() ).kill(); // kill previous running animations
		this.cache.tl = new TimelineLite()

			// - hide old submenu
			.set( elsOldSubmenuItems, {
				css: {
					pointerEvents: 'none',
				},
			} )
			.to( elsOldSubmenuItems, speedify( 0.3 ), {
				css: {
					opacity: 0,
				},
			} )

			// - show new submenu
			.to( elNewSubmenu, speedify( 0.3 ), {
				css: {
					opacity: 1,
				},
			}, `-=${ speedify( 0.1 ) }` )
			.set( [ elNewSubmenu, elsNewSubmenuItems ], {
				css: {
					pointerEvents: 'auto',
				},
			} )

			// - scale background properly (+ move backdrop)
			.to( this.el.submenuBackground, speedify( 0.3 ), {
				css: {
					scaleY: submenuScale, transformOrigin: '50% 0%',
				},
			}, speedify( 0.2 ) )
			.to( this.el.navBackdropMain, speedify( 0.3 ), {
				css: {
					y: backdropToY, transformOrigin: '50% 0%',
				},
			}, speedify( 0.2 ) );
	}

	/**
	 * Close the submenu (level 1+) and go back to its parent
	 *
	 * @param {Node} elOldSubmenu the submenu to close (= child)
	 * @param {Node} elOldControl the control of the child submenu
	 * @param {Node} elNewSubmenu the submenu to open (= parent)
	 * @param {Node} elNewControl the control of the parent submenu
	 * @param {boolean} animate should we animate?
	 */
	gotoParentSubmenu( elOldSubmenu, elOldControl, elNewSubmenu, elNewControl, animate = true ) {
		// update control items
		// elOldControl.removeAttribute('data-nav-current');
		elOldControl.setAttribute( 'aria-expanded', 'false' );
		elNewControl.setAttribute( 'aria-expanded', 'true' );
		// elNewControl.setAttribute('data-nav-current', 'true');

		// vars
		const speed = animate ? 1 : 0;
		const speedify = ( seconds ) => speed * seconds;
		const elsOldSubmenuItems = this.getSubmenuItemsToHide( elOldSubmenu );
		const elsNewSubmenuItems = this.getSubmenuItemsToHide( elNewSubmenu );
		const submenuHeight = elNewSubmenu.offsetHeight;
		const backdropToY = this.cache.menubarHeight + submenuHeight;
		const submenuScale = submenuHeight / this.el.submenuBackground.offsetHeight;

		// animate switching
		this.cache.tl.stop()
			.seek( this.cache.tl.duration() ).kill(); // kill previous running animations
		this.cache.tl = new TimelineLite()

			// - hide old submenu
			.set( [ elOldSubmenu, elsOldSubmenuItems ], {
				css: {
					pointerEvents: 'none',
				},
			} )
			.to( elOldSubmenu, speedify( 0.3 ), {
				css: {
					opacity: 0,
				},
			} )

			// - show new submenu
			.to( elsNewSubmenuItems, speedify( 0.3 ), {
				css: {
					opacity: 1,
				},
			}, `-=${ speedify( 0.1 ) }` )
			.set( elsNewSubmenuItems, {
				css: {
					pointerEvents: 'auto',
				},
			} )

			// - scale background properly (+ move backdrop)
			.to( this.el.submenuBackground, speedify( 0.3 ), {
				css: {
					scaleY: submenuScale, transformOrigin: '50% 0%',
				},
			}, speedify( 0.2 ) )
			.to( this.el.navBackdropMain, speedify( 0.3 ), {
				css: {
					y: backdropToY, transformOrigin: '50% 0%',
				},
			}, speedify( 0.2 ) );
	}

	/**
	 * Mobile: Open a child submenu
	 *
	 * @param {Node} elOldSubmenu the previous submenu (= parent)
	 * @param {Node} elNewSubmenu the submenu to open (= child)
	 * @param {Node} elNewControl the control of the submenu to open
	 * @param {boolean} animate should we animate?
	 */
	mobileGotoChildSubmenu( elOldSubmenu, elNewSubmenu, elNewControl, animate = true ) {
		// update control items
		// elOldControl.removeAttribute('data-nav-current');
		elNewControl.setAttribute( 'aria-expanded', 'true' );
		// elNewControl.setAttribute('data-nav-current', 'true');

		// vars
		const speed = animate ? 1 : 0;
		const speedify = ( seconds ) => speed * seconds;
		const elsOldSubmenuItems = elOldSubmenu
			? this.getSubmenuItemsToHide( elOldSubmenu )
			: [];
		const elsNewSubmenuItems = this.getSubmenuItemsToHide( elNewSubmenu );

		// animate UI
		this.cache.tl.stop()
			.seek( this.cache.tl.duration() ).kill(); // kill previous running animations
		this.cache.tl = new TimelineLite()

			// - set defaults
			.set( elsOldSubmenuItems, {
				css: {
					xPercent: 0,
				},
			} )
			.set( elNewSubmenu, {
				css: {
					x: 0,
					xPercent: 100,
					opacity: 1,
				},
			} )
			.set( elsNewSubmenuItems, {
				css: {
					xPercent: 0,
					opacity: 1,
				},
			} )

			// - hide parent submenu
			.set( elsOldSubmenuItems, {
				css: {
					pointerEvents: 'none',
				},
			} )
			.to( elsOldSubmenuItems, speedify( 0.3 ), {
				css: {
					xPercent: -100 * MOBILE_MENU_TRANSLATE_PERCENTAGE,
				},
				ease: Power2.easeOut,
			}, 0 )

			// - show child submenu
			.to( elNewSubmenu, speedify( 0.3 ), {
				css: {
					xPercent: 0,
				},
				ease: Power2.easeOut,
			}, 0 )
			.set( [ elNewSubmenu, elsNewSubmenuItems ], {
				css: {
					pointerEvents: 'auto',
				},
			} )

			// - set end values (to make sure layout stays fluid)
			.set( elsOldSubmenuItems, {
				css: {
					xPercent: MOBILE_MENU_TRANSLATE_PERCENTAGE * 100,
				},
			} );

		// .call(() => {
		// 	console.log('call');
		// 	clearAllBodyScrollLocks();
		// 	disableBodyScroll(elNewSubmenu.parentElement);
		// });
	}

	/**
	 * Mobile: Close a child submenu
	 *
	 * @param {Node} elOldSubmenu the previous submenu (= parent)
	 * @param {Node} elOldControl the control of the submenu to close
	 * @param {Node} elNewSubmenu the submenu to open (= child)
	 * @param {Node} elNewControl the control of the submenu to open
	 * @param {boolean} animate should we animate?
	 */
	mobileGotoParentSubmenu(
		elOldSubmenu, elOldControl, elNewSubmenu, elNewControl, animate = true
	) {
		// update control items
		// elOldControl.removeAttribute('data-nav-current');
		elOldControl.setAttribute( 'aria-expanded', 'false' );
		if ( elNewControl ) { // could be null if we reach the top level
			elNewControl.setAttribute( 'aria-expanded', 'true' );
		}
		// elNewControl.setAttribute('data-nav-current', 'true');

		// vars
		const speed = animate ? 1 : 0;
		const speedify = ( seconds ) => speed * seconds;
		const elsOldSubmenuItems = this.getSubmenuItemsToHide( elOldSubmenu );
		const elsNewSubmenuItems = elNewSubmenu
			? this.getSubmenuItemsToHide( elNewSubmenu )
			: [];

		// animate UI
		this.cache.tl.stop()
			.seek( this.cache.tl.duration() ).kill(); // kill previous running animations
		this.cache.tl = new TimelineLite()

			// - set defaults
			.set( elsNewSubmenuItems, {
				css: {
					xPercent: -100 * MOBILE_MENU_TRANSLATE_PERCENTAGE,
				},
			} )

			// - show parent submenu
			.set( elsNewSubmenuItems, {
				css: {
					pointerEvents: 'auto',
				},
			} )
			.to( elsNewSubmenuItems, speedify( 0.3 ), {
				css: {
					xPercent: 0,
				},
				ease: Power2.easeOut,
			}, 0 )

			// - hide child submenu
			.set( [ elOldSubmenu, elsOldSubmenuItems ], {
				css: {
					pointerEvents: 'none',
				},
			} )
			.to( elOldSubmenu, speedify( 0.3 ), {
				css: {
					xPercent: 100,
				},
				ease: Power2.easeOut,
			}, 0 )

			// - set end values (to make sure layout stays fluid)
			.set( elOldSubmenu, {
				css: {
					xPercent: 100,
				},
			} );

		// .call(() => {
		// 	console.log('call');
		// 	clearAllBodyScrollLocks();
		// 	disableBodyScroll(elNewSubmenu.parentElement);
		// });
	}

	/**
	 * Reset the UI (transforms, attributes,…)
	 *
	 * @param root
	 */
	resetUI( root = this.element ) {
		// Reset body scroll to ensure it's not locked
		clearAllBodyScrollLocks();

		// Do not continue if root is null
		if ( root === null ) {
			return;
		}

		// reset attributes
		root.querySelectorAll( SELECTOR_ANY_OPEN_CONTROL ).forEach( ( el ) => el.setAttribute( 'aria-expanded', 'false' ) );

		// reset styles
		const selectors = [
			SELECTORS_SUBMENU_ITEMS_TO_HIDE.join( ',' ),
			SELECTOR_SUBMENU,
			SELECTOR_SUBMENU_BACKGROUND,
			SELECTOR_NAV_BACKDROP_TOP,
			SELECTOR_NAV_BACKDROP_MAIN,
			SELECTOR_NAV_INNER,
		].join( ',' );

		new TimelineLite()
			.set( root.querySelectorAll( selectors ), {
				clearProps: 'all',
			} )
			.set( root, {
				clearProps: 'all',
			} );
	}

	// #endregion

	// #region Helpers: Page change

	/**
	 * Update the wordpress classes for current, parent and ancestors menu items
	 * …we need this if the navigation doesn't get re-rendered by wordpress
	 * on page change (barba.js navigation for example).
	 */
	updateCurrentPageItems() {
		// reset all items
		this.getScopedElements( `.${ CLASS_MENUITEM_CURRENT }` ).forEach( ( el ) => el.classList.remove( CLASS_MENUITEM_CURRENT ) );
		this.getScopedElements( `.${ CLASS_MENUITEM_CURRENT_PARENT }` ).forEach( ( el ) => el.classList.remove( CLASS_MENUITEM_CURRENT_PARENT ) );
		this.getScopedElements( `.${ CLASS_MENUITEM_CURRENT_ANCESTOR }` ).forEach( ( el ) => el.classList.remove( CLASS_MENUITEM_CURRENT_ANCESTOR ) );

		// vars
		const elCurrent = this.getCurrentPageMatchingMenuItem();

		// bail early if no matching item
		if ( ! elCurrent ) {
			return;
		}

		// vars
		const elsAncestors = this.getMenuItemAncestors( elCurrent );
		const elParent = elsAncestors && elsAncestors.length ? elsAncestors[ 0 ] : null;

		// add classes
		elCurrent.classList.add( CLASS_MENUITEM_CURRENT );
		if ( elParent ) {
			elParent.classList.add( CLASS_MENUITEM_CURRENT_PARENT );
		}
		elsAncestors.forEach( ( el ) => el.classList.add( CLASS_MENUITEM_CURRENT_ANCESTOR ) );
	}

	/**
	 *
	 * @param {Object} langs Language object
	 */
	updateLanguageSelector( langs ) {
		if ( typeof langs === 'undefined' || langs === null || langs === false ) {
			return;
		}

		this.el.languagesWrap.classList[ ( langs.hasTranslations ? 'remove' : 'add' ) ]( 'is-hidden' );

		Object.keys( langs.items ).forEach( ( key ) => {
			const l = langs.items[ key ];
			const linkEl = this.el.languagesWrap.querySelector( `.languages__link[lang="${ l.code }"` );
			const itemEl = linkEl.parentElement;

			itemEl.classList[ ( l.isActive ? 'add' : 'remove' ) ]( 'is-current' );
			linkEl.href = l.link;
		} );
	}

	/**
	 * Get the menu item that matches current page's post id
	 *
	 * @return {Element|null} element if found; else null
	 */
	getCurrentPageMatchingMenuItem() {
		// get post id from the dom
		const elCurrentPost = document.querySelector( SELECTOR_CURRENT_POSTID );
		if ( ! elCurrentPost ) {
			return null;
		} // bail early if no element containing post id

		// return matching menu element
		const postId = `${ elCurrentPost.id }`.replace( 'post-', '' );
		return this.getScopedElement( `[data-postid="${ postId }"]` );
	}

	getMenuItemAncestors( elMenuItem ) {
		// vars
		const elsAncestors = [];
		let elCurrentMenuItem = elMenuItem.parentElement.closest( SELECTOR_ANY_MENUITEM_WRAPPER );

		// loop through ancestors (will stop when dom query returns null)
		while ( elCurrentMenuItem !== null ) {
			elCurrentMenuItem = elCurrentMenuItem.parentElement.closest( SELECTOR_ANY_MENUITEM_WRAPPER );
			if ( elCurrentMenuItem ) {
				const els = getChildrenMatches( elCurrentMenuItem, SELECTOR_ANY_MENUITEM );
				if ( els && els.length ) {
					elsAncestors.push( ...els );
				}
			}
		}

		// return
		return elsAncestors;
	}

	// #endregion

	/**
	 * Destroy the component's instance (unbind events, reset UI,…)
	 */
	destroy() {
		this.scrollHandler.destroy();
		this.resetUI();
		super.destroy();
	}
}
