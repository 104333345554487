import getChildrenMatches from './getChildrenMatches';

/**
 * Recursively get all direct descendant elements that match a selector
 * Use case: you want to get all `> .children > li > .sub-children` of an element
 * …achieve it with `getRecursiveChildrenMatches([elem], ['.children', 'li', '.sub-children'])`
 *
 * @param  {Array<Element>} elems   The element to get direct descendants for
 * @param  {Array} selectors     The selectors to match direct children against
 * @return {Array<Element>}               The matching elements
 */
// eslint-disable-next-line func-names
const getRecursiveChildrenMatches = function( elems, selectors ) {
	// bail early if elems are empty
	if ( ! elems.length ) {
		return [];
	}

	// vars
	const childSelector = selectors[ 0 ];

	// get direct children that match the current selector
	const childElems = [];
	elems.forEach( ( el ) => childElems.push( ...getChildrenMatches( el, childSelector ) ) );

	// bail early if no matching child found
	if ( ! childElems.length ) {
		return [];
	}

	// go deeper
	if ( selectors.length > 1 ) {
		return getRecursiveChildrenMatches( childElems, selectors.slice( 1 ) );
	}

	// return elements
	return childElems;
};

export default getRecursiveChildrenMatches;
