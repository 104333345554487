import BaseView from '../../../js/base-view';

export default class PaymentChoice extends BaseView {
	init() {
		this.props = {
			name: 'payment',
		};

		this.refs = {
			inputs: Array.from( this.element.querySelectorAll( 'input' ) ),
		};
	}

	getName() {
		return this.props.name;
	}

	reset() {
		this.resetError();
	}

	setError( error ) {
		this.element.setAttribute( 'data-error', error );
	}

	resetError() {
		this.element.removeAttribute( 'data-error' );
	}

	focus() {
		this.refs.inputs[ 0 ].focus();
	}
}
