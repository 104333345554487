import BaseView from '../../../js/base-view';

const CARD_IMG_SELECTOR = '.card-profile__img img.is-hidden';

export default class ProfileList extends BaseView {
	bind() {
		Array.from( this.element.querySelectorAll( CARD_IMG_SELECTOR ) )
			.filter( ( img ) => img.complete )
			.forEach( ( img ) => img.classList.remove( 'is-hidden' ) );

		this.on( 'load', CARD_IMG_SELECTOR, ( e ) => e.target.classList.remove( 'is-hidden' ) );
	}
}
