import BaseView from '../../../js/base-view';
import './lightgallery/js/lightgallery';
import './lightgallery/plugins/lg-video';
import './lightgallery/plugins/lg-thumbnail';
import './lightgallery/plugins/lg-zoom';

const CONFIG = {
	selector: '[data-gallery-img]',
	subHtmlSelectorRelative: true,
	speed: 500,
	getCaptionFromTitleOrAlt: false,
	thumbnail: true,
	loadYoutubeThumbnail: false,
	loadVimeoThumbnail: false,
	thumbWidth: 100,
	thumbContHeight: 100 * 9 / 16 + 20, // force 16/9 ratio (there is 20px padding)
	loadDailymotionThumbnail: false,
	download: false,
	autoplayControls: false,
	actualSize: false,
	share: false,
	videojs: true,
	videoMaxWidth: '1200px',
	youtubePlayerParams: {
		modestbranding: 1,
		showinfo: 0,
		rel: 0,
		controls: 1,
	},
};

export default class Gallery extends BaseView {
	/**
	 * Entrypoint
	 */
	initialize() {
		// avoid error in lightgallery when picturefill is not included
		if ( typeof ( window.picturefill ) !== 'function' ) {
			window.picturefill = ( obj ) => null;
		}

		// initialize lightgallery.js
		// eslint-disable-next-line no-undef
		lightGallery( this.element, CONFIG );
	}

	/**
	 * Gets current gallery object
	 */
	getGallery() {
		return window.lgData[ this.element.getAttribute( 'lg-uid' ) ];
	}

	/**
	 * Before the component gets destroyed
	 */
	beforeDestroy() {
		// destroy lightgallery.js
		this.getGallery().destroy( true );
	}
}
