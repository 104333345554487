/**
 * This file manage the animations activeduring the scroll.
 */

// THESE 3 CONSTANTS NEED TO BE SYNCED WITH CSS
const TRANSITION_ITEM_SELECTOR = '.card';
const TRANSITION_ITEM_DELAY = 150; // ms
const TRANSITION_DURATION = 400; // ms

const STATE_APPEAR = 'is-appear-on';
const SECTION_ATTR = 'data-appear';
const SECTION_SELECTOR = `[${ SECTION_ATTR }]`;

const APPEARANCE_OFFSET = '-40%'; // Observer bottom margin

// intersection observer options
const OBSERVER_OPTIONS = {
	root: null,
	rootMargin: `0% 0% ${ APPEARANCE_OFFSET } 0%`,
	threshold: 0,
};

let observer = null;
let appearItems = [];

function runAppearance( item ) {
	return new Promise( ( resolve ) => {
		item.target.classList.add( STATE_APPEAR );
		setTimeout( () => {
			item.target.removeAttribute( SECTION_ATTR );
			item.target.classList.remove( STATE_APPEAR );
			resolve();
		}, item.endDelay );
	} );
}

function callback( entries ) {
	entries.forEach( ( entry ) => {
		if ( ! entry.isIntersecting ) {
			return;
		}

		const item = appearItems.find( ( i ) => i.target === entry.target );
		if ( ! item.isAppearanceOn ) {
			item.isAppearanceOn = true;
			runAppearance( item )
				.then( () => {
					observer.unobserve( entry.target );
				} );
		}
	} );
}

function createAppearItem( item ) {
	const itemsCount = Array.from( item.querySelectorAll( TRANSITION_ITEM_SELECTOR ) ).length;

	return {
		isAppearanceOn: false,
		target: item,
		itemsCount,
		endDelay: ( ( itemsCount - 1 ) * TRANSITION_ITEM_DELAY ) + TRANSITION_DURATION,
	};
}

function init() {
	appearItems = Array.from( document.querySelectorAll( SECTION_SELECTOR ) ).map( createAppearItem );

	if ( ! appearItems.length ) {
		return false;
	}

	observer = new IntersectionObserver( callback, OBSERVER_OPTIONS );
	appearItems.forEach( ( item ) => observer.observe( item.target ) );

	return true;
}

function destroy() {
	if ( observer !== null ) {
		observer.disconnect();
		observer = null;
	}
	appearItems = [];
}

export default {
	init,
	destroy,
};
