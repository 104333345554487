import { debounce } from 'lodash-es';
import Stickyfill from 'stickyfilljs';
import BaseView from '../../../js/base-view';

const CLASS_SUBMENU_ACTIVE = 'profile-nav--submenu';

const BREAKPOINT_DESKTOP_MODE = 1024; // submenu should be in desktop version when >= …px
const DEBOUNCE_DELAY = 10; // ms
const MENU_HEIGHT_DESKTOP_SMALL = 96;
const MENU_HEIGHT_MOBILE_SMALL = 68;

export default class ProfileNav extends BaseView {
	bind() {
		this.state = {
			submenuActive: false,
			menuHeight: MENU_HEIGHT_DESKTOP_SMALL,
		};

		this.profileNavOffsetTop = this.element.offsetTop;
		Stickyfill.add( this.element );
		this.handleViewportResize();
		this.handleProfileNav();
		this.bindEventListeners();
	}

	bindEventListeners() {
		// viewport
		viewport_service.on( 'change', () => this.handleViewportResize() );

		// page scroll / viewport scroll to update active submenu
		this.onScrollWindow = debounce( this.handleProfileNav.bind( this ), DEBOUNCE_DELAY );
		window.addEventListener( 'scroll', this.onScrollWindow, { passive: true } );
	}

	/* VIEWPORT */
	/**
	 * When screen size changes
	 */
	handleViewportResize() {
		this.state.menuHeight = this.isMobile() ? MENU_HEIGHT_MOBILE_SMALL : MENU_HEIGHT_DESKTOP_SMALL;

		if ( this.state.submenuActive ) {
			return;
		}
		this.profileNavOffsetTop = this.element.offsetTop;
	}

	/**
	 * Return if viewport mobile
	 */
	isMobile() {
		return viewport_service.getCurrentWidth() < BREAKPOINT_DESKTOP_MODE;
	}

	/* SCROLL */
	/**
	 * Update profile nav
	 */
	handleProfileNav() {
		if ( this.profileNavOffsetTop - window.pageYOffset <= this.state.menuHeight ) {
			this.element.classList.add( CLASS_SUBMENU_ACTIVE );
			this.state.submenuActive = true;
		}
		else {
			this.element.classList.remove( CLASS_SUBMENU_ACTIVE );
			this.state.submenuActive = false;
		}
	}

	destroy() {
		super.destroy();
		// page scroll
		window.removeEventListener( 'scroll', this.onScrollWindow, { passive: true } );
	}
}
