import BaseView from '../../../js/base-view';

const ACTIVE_CLASS = 'is-active';
const IS_HIDDEN = 'no-letters';

const SELECTOR_BTN = '.alphabetical-tabs__btn';
const SELECTOR_ALL = '.alphabetical-tabs__item--all button';
const SELECTOR_LETTER = '.alphabetical-tabs__item--letter button';

export default class AlphabeticalTabs extends BaseView {
	init() {
		this.refs = {
			all: this.element.querySelector( SELECTOR_ALL ),
			letters: Array.from( this.element.querySelectorAll( SELECTOR_LETTER ) ),
			buttons: Array.from( this.element.querySelectorAll( SELECTOR_BTN ) ),
		};

		this.state = {
			activeIdx: 0,
		};

		this.bindEvents();

		return this; // For chaining
	}

	bindEvents() {
		this.on( 'click', SELECTOR_BTN, this.onClick.bind( this ) );
	}

	onClick( event ) {
		event.preventDefault();

		const nextActive = this.refs.buttons.indexOf( event.target );
		this.setActive( nextActive );

		this.trigger( 'change', { detail: nextActive > 0 ? event.target.textContent : null }, false );
	}

	setActive( newIdx = 0 ) {
		this.refs.buttons[ this.state.activeIdx ].classList.remove( ACTIVE_CLASS );
		this.refs.buttons[ newIdx ].classList.add( ACTIVE_CLASS );
		this.state.activeIdx = newIdx;
	}

	resetLetters() {
		this.refs.letters.forEach( ( letter ) => letter.removeAttribute( 'disabled' ) );
	}

	setEnabledLetters( enabled = [] ) {
		if ( enabled.length === 0 ) {
			this.hide();
			return;
		}
		if ( enabled.length > 1 ) {
			this.setActive();
		}

		this.show();

		this.resetLetters();
		this.refs.letters.forEach( ( letter ) => {
			if ( enabled.map( ( l ) => l.toUpperCase() ).indexOf( letter.textContent ) < 0 ) {
				letter.setAttribute( 'disabled', true );
			}
		} );
	}

	show() {
		this.element.classList.remove( IS_HIDDEN );
	}

	hide() {
		this.element.classList.add( IS_HIDDEN );
	}
}
