import BaseView from '../../../js/base-view';

import SearchInput from '../../molecules/search-input/search-input';
import SearchFilter from '../../molecules/search-filter/search-filter';

const SELECTOR_INPUT = '[data-search-input]';
const SELECTOR_FILTER = '[data-search-filter]';

const MIN_SEARCH_LENGTH = 1;

export default class SearchBar extends BaseView {
	init() {
		this.state = {
			text: null,
			filter: null,
		};

		this.refs = {
			input: new SearchInput( this.element.querySelector( SELECTOR_INPUT ) ).init(),
			filters: new SearchFilter( this.element.querySelector( SELECTOR_FILTER ) ).init(),
		};

		this.bindEvents();

		return this; // For chaining
	}

	bindEvents() {
		this.on( 'input:change', SELECTOR_INPUT, this.onInputChange.bind( this ) );
		this.on( 'input:submit', SELECTOR_INPUT, this.onInputSubmit.bind( this ) );
		this.on( 'filter:change', SELECTOR_FILTER, this.onFilterChange.bind( this ) );
	}

	onInputChange( { detail } ) {
		this.state.text = detail;

		if ( detail.length < MIN_SEARCH_LENGTH ) {
			this.refs.input.updateSuggestion();
			this.refs.input.updateNbResults();
			return;
		}

		this.trigger( 'search:change', { detail }, false );
	}

	onInputSubmit( { detail } ) {
		this.state.text = detail;
		this.refs.filters.reset();
		this.state.filter = this.refs.filters.updateActiveOptions( this.state.text );

		this.submit();
	}

	onFilterChange( { detail } ) {
		this.state.filter = detail;
		this.submit();
	}

	submit() {
		this.trigger( 'search:submit', { detail: this.state }, false );
	}

	// #######################
	// #region PUBLIC METHODS
	// #######################

	updateInputSuggestions( items = [] ) {
		this.refs.input.updateSuggestion( items );
	}

	updateInputNbResults( nb = 0 ) {
		this.refs.input.updateNbResults( nb );
	}

	updateFilters( filters = {} ) {
		this.refs.filters.updateEnabledOptions( filters );
	}

	getCategoryTitle( name ) {
		return this.refs.filters.getCategoryTitle( name );
	}

	// #######################
	// #endregion
	// #######################
}
