import A11yDialog from 'a11y-dialog';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import BaseView from '../../../js/base-view';

export default class Modal extends BaseView {
	bind() {
		this.dialog = new A11yDialog( this.element, this.element.parentElement.querySelectorAll( '*:not(.modal)' ) );

		this.refs = {
			overlay: this.element.querySelector( '.modal__overlay' ),
			content: this.element.querySelector( '.modal__dialog-content__inner' ),
		};

		// clicking the backdrop of the native dialog is not working on chrome
		// so we listen for the click event
		if ( this.dialog.useDialog ) {
			this.clickHandler = this.handleClick.bind( this );
			this.on( 'click', this.clickHandler );
		}
		else {
			// also remove hidden class from the alternative overlay fallback
			this.refs.overlay.classList.remove( 'is-hidden' );
			this.bindOverlayClick();
		}

		this.dialog.on( 'show', this.onShow.bind( this ) );
		this.dialog.on( 'hide', this.onHide.bind( this ) );
		this.on( 'click', 'a', this.onLinkClick.bind( this ) );
	}

	handleClick( event ) {
		if ( event.target.classList.contains( 'modal__dialog' ) ) {
			this.dialog.hide();
		}
	}

	onLinkClick() {
		this.dialog.hide();
	}

	bindOverlayClick() {
		this.hideHandler = this.hideOverlay.bind( this );
		this.on( 'click', '.modal__dialog-close', this.hideHandler, false );
	}

	hideOverlay() {
		this.refs.overlay.classList.add( 'is-hidden' );
	}

	onShow() {
		disableBodyScroll( this.refs.content );
		this.refs.overlay.classList.remove( 'is-hidden' );
	}

	onHide() {
		enableBodyScroll( this.refs.content );
	}

	destroy() {
		this.dialog.destroy();
		super.destroy();
	}
}
