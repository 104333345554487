/* global grecaptcha dataLayer, Sentry */
/* eslint-env browser */

// Based on jobtrek-website form : Check for more

import { isNil } from 'lodash-es';
import BaseView from '../../../js/base-view';
import Input from '../../atoms/input/input';
import InputSelect from '../../atoms/input/input-select';
import PaymentChoice from '../../molecules/payment-choice/payment-choice';
import UploadFile from '../../molecules/upload-file/upload-file';
import loadScript from '../../../js/utils/load-script';

const IS_SENDING = 'is-sending';
const CLASS_SUCCESS = 'is-success';
const CLASS_ERROR = 'is-error';
const CLASS_PAYMENT = 'is-paying';
const CLASS_DISABLED = 'is-disabled';

const DELAY_BEFORE_RESET = 8000;
const TIMEOUT_ERROR = 10000; // If no response, it's an error
const DELAY_PAYMENT_REDIRECT = 3000;

const RECAPTCHA_SELECTOR = 'input[name=rcpa]';
const FIELD_SELECTOR = '[data-input-field],[data-upload-file],[data-input-select],[data-payment]';

const STRIPE_URL_API = 'https://js.stripe.com/v3/';

export default class Form extends BaseView {
	bind() {
		this.init();

		if ( ! this.element.hasAttribute( 'data-no-ajax' ) ) {
			this.on( 'submit', this.onSubmit.bind( this ) );
			this.element.setAttribute( 'novalidate', true );
		}
	}

	init() {
		this.props = {
			hasRecaptcha: ( this.getScopedElement( RECAPTCHA_SELECTOR ) !== null ),
			hasStripe: ( this.element.hasAttribute( 'data-pkey' ) ),
		};

		this.fields = Array.from( this.getScopedElements( FIELD_SELECTOR ) )
			.map( ( element ) => {
				let View = Input;
				if ( element.hasAttribute( 'data-upload-file' ) ) {
					View = UploadFile;
				}
				else if ( element.hasAttribute( 'data-input-select' ) ) {
					View = InputSelect;
				}
				else if ( element.hasAttribute( 'data-payment' ) ) {
					View = PaymentChoice;
				}

				const v = new View( element );
				v.init();
				return v;
			} );

		this.maybeInitRecaptcha();
		this.initStripe();

		if ( this.element.classList.contains( CLASS_SUCCESS ) ) {
			this.triggerDataLayerEvent();
		}
	}

	/**
	 *
	 */
	maybeInitRecaptcha() {
		if ( ! this.props.hasRecaptcha ) {
			return;
		}

		// If the script has already been requested, don't request it again
		if ( typeof window.recaptcha !== 'undefined' ) {
			this.initRecaptcha();
		}
		else {
			this.on( 'recaptcha:loaded', this.initRecaptcha.bind( this ), true );
		}
	}

	/**
	 * Render the reCAPTCHA instance &
	 * register a global function to be accessed by reCAPTCHA
	 */
	initRecaptcha() {
		this.element.classList.remove( CLASS_DISABLED );
		const recaptchaInput = this.getScopedElement( RECAPTCHA_SELECTOR );

		// Render the recaptcha element
		this.recaptchaId = grecaptcha.render(
			recaptchaInput,
			{
				size: 'invisible',
				sitekey: recaptchaInput.value,
				callback: this.submitHandler.bind( this ),
			},
		);
	}

	/**
	 * Wait for reCAPTCHA to be loaded and available
	 */
	waitRecaptchaLoaded() {
		if ( isNil( window.grecaptcha ) ) {
			if ( this.recaptchaMAxLoadTry > 0 ) {
				this.recaptchaMAxLoadTry -= 1;
				setTimeout( this.waitRecaptchaLoaded.bind( this ), 200 );
			}
		}
		else {
			this.initRecaptcha();
		}
	}

	/**
	 * Load
	 */
	initStripe() {
		if ( typeof Stripe === 'undefined' ) {
			setTimeout( () => {
				if ( typeof Stripe === 'undefined' ) {
					loadScript( STRIPE_URL_API );
				}
			}, 500 );
		}
	}

	doStripe( data ) {
		this.stripe = new Stripe( this.element.getAttribute( 'data-pkey' ) );

		this.stripe.redirectToCheckout( {
			items: [ { sku: data.sku, quantity: 1 } ],
			successUrl: `${ this.element.action }?payment-success#${ this.element.id }`,
			cancelUrl: `${ this.element.action }?payment-cancelled#${ this.element.id }`,
			customerEmail: data.email,
			clientReferenceId: data.token,
		} )
			.then( ( result ) => {
				console.error( 'Stripe checkout:', result.error.message );
				this.showFormError( result.error.message );
			} );
	}

	triggerDataLayerEvent() {
		if ( typeof dataLayer === 'undefined' ) {
			return;
		}

		dataLayer.push( {
			event: this.element.id,
			PageCategory: window.location.pathname.replace( /^\/+|\/+$/g, '' ),
			status: 'success',
		} );
	}

	/**
	 * Handle the submit Event and execute the appropiate process
	 *
	 * @param {Event} event
	 */
	onSubmit( event ) {
		event.preventDefault();

		// Execute recaptcha validation if enabled or process to form submission
		if ( this.props.hasRecaptcha ) {
			grecaptcha.execute( this.recaptchaId );
			return;
		}

		this.submitHandler();
	}

	submitHandler( token = null ) {
		// Do no process twice
		if ( this.element.classList.contains( IS_SENDING ) ) {
			return;
		}
		this.element.classList.add( 'fixed-message', IS_SENDING );

		const data = new FormData( this.element );

		// add the recaptcha token to the form data
		if ( this.props.hasRecaptcha && token ) {
			data.append( 'g-recaptcha-response', token );
		}

		// Clear inputs error params
		this.fields.forEach( ( field ) => field.resetError() );

		// Send the request
		const requestPromise = new Promise( ( resolve, reject ) => {
			fetch( this.element.action, {
				method: 'POST',
				body: data,
				credentials: 'same-origin',
				headers: new Headers( { 'X-Request-Ajax': true } ),
			} )
				.then( ( promise ) => resolve( promise.json() ) )
				.catch( reject );
		} );

		// Define timeout for the request
		const timeoutPromise = new Promise(
			( resolve, reject ) => setTimeout( reject, TIMEOUT_ERROR )
		);

		Promise.race( [ requestPromise, timeoutPromise ] )
			.then( ( response ) => {
				// Clear recaptcha as needed and discribed in the doc
				if ( this.props.hasRecaptcha ) {
					grecaptcha.reset( this.recaptchaId );
				}

				if ( response.success ) {
					if ( ! isNil( response.data.stripe ) ) {
						this.element.classList.add( CLASS_PAYMENT );
						setTimeout( this.doStripe.bind( this, response.data.stripe ), DELAY_PAYMENT_REDIRECT );
					}
					else {
						this.trigger( 'form:success' );
						this.element.classList.add( CLASS_SUCCESS );
						setTimeout( this.reset.bind( this ), DELAY_BEFORE_RESET );

						this.triggerDataLayerEvent();
					}
				}
				else if ( ! isNil( response.data.global ) ) {
					console.error( 'Form global:', response.data.global );
					this.showFormError( 'global form error' );
				}
				else {
					Object.keys( response.data ).forEach( ( name, i ) => {
						const field = this.fields.find( ( f ) => ( f.getName() === name ) );
						if ( typeof field !== 'undefined' ) {
							field.setError( response.data[ name ] );

							if ( i === 0 ) {
								field.focus();
							}
						}
					} );
				}

				this.element.classList.remove( IS_SENDING );
			} )
			.catch( ( err ) => {
				console.error( err );
				this.showFormError( err );
			} );
	}

	showFormError( err ) {
		this.trigger( 'form:error' );
		this.element.classList.remove( IS_SENDING );
		this.element.classList.add( CLASS_ERROR );
		setTimeout( this.reset.bind( this ), DELAY_BEFORE_RESET );

		if ( typeof Sentry !== 'undefined' ) {
			Sentry.captureException( err );
		}
	}

	reset() {
		this.fields.forEach( ( field ) => field.reset() );
		this.element.reset();
		this.element.classList.remove( CLASS_SUCCESS, CLASS_ERROR );
	}

	destroy() {
		this.fields.forEach( ( field ) => field.destroy() );
		super.destroy();
	}
}
