import { debounce } from 'lodash-es';
import BaseView from '../../../js/base-view';

const BREAKPOINT = 1024;
const UPDATE_TITLE_DURATION = 700;

const ATTR_BREAKER_TITLE = 'data-section-breaker';
// const ATTR_HIDER = 'data-scroll-indicator-hide';

const SELECTOR_INNER = '.scroll-indicator__inner';
const SELECTOR_HEADER = '.header';
const SELECTOR_BOTTOM = '[data-scroll-indicator-bottom]';
const SELECTOR_SECTION_BREAKER = '[data-section-breaker]';
const SELECTOR_HIDER = '[data-scroll-indicator-hide]';

const CLASS_IS_TOP = 'is-at-top';
const CLASS_IS_STICKY = 'is-sticky';
const CLASS_IS_BOTTOM = 'is-at-bottom';
const CLASS_IS_HIDDEN = 'is-hidden';

const CLASS_SHOW_ENTER = 'show-enter';
const CLASS_SHOW_TO = 'show-to';
const CLASS_HIDE_ENTER = 'hide-enter';
const CLASS_HIDE_TO = 'hide-to';

export default class ScrollIndicator extends BaseView {
	initialize() {
		// Do nothing if the section indicator is not shown
		if ( viewport_service.getViewportWidth() < BREAKPOINT ) {
			return;
		}

		this.props = {};

		this.refs = {
			inner: this.element.querySelector( SELECTOR_INNER ),

			header: document.querySelector( SELECTOR_HEADER ),
			bottom: document.querySelector( SELECTOR_BOTTOM ),
			breakers: Array.from( document.querySelectorAll( SELECTOR_SECTION_BREAKER ) ),
			hiders: Array.from( document.querySelectorAll( SELECTOR_HIDER ) ),
		};

		// Bail early if none of the elements are present in the page
		if ( this.refs.header === null ||
			this.refs.bottom === null ||
			this.refs.breakers.length === 0 ) {
			return;
		}

		this.state = {
			isReady: false,
			isUpdating: false,
			currentTitle: 0,

			// Values need to be set after DOM is fully loaded
			// and updated on window resize
			elementHeight: null,
			topTranslate: null,
			bottomHeight: null,
			stickyThreshold: {
				top: null,
				bottom: null,
			},

			sections: [],
			hiderSections: [],
		};

		this.bindEvents();
		this.on( 'initial-appearance:end', this.init.bind( this ), true );
	}

	init() {
		this.updateSectionThresholds();
		this.updateHiderThresholds();

		if ( this.state.sections.length ) {
			this.refs.inner.lastElementChild.innerHTML = this.state.sections[ 0 ].title;
		}

		this.updateSizes();
		this.updateStickyPosition();

		this.state.isReady = true;
		this.showInitial();
	}

	bindEvents() {
		this.onScrollHandler = debounce( this.onScroll.bind( this ), 10 );

		// viewport scroll to enable/disable sticky feature
		window.addEventListener(
			'scroll', this.onScrollHandler,
			{ passive: true },
		);
	}

	onScroll() {
		requestAnimationFrame( () => {
			this.updateStickyPosition();

			this.toggleHide( this.isHidden() );

			if ( this.state.sections.length > 0 ) {
				this.updateTitle( this.getNextSectionIdx() );
			}
		} );
	}

	updateStickyPosition() {
		// Do not continue if we don't have the element height yet
		if ( this.state.elementHeight === null ) {
			return;
		}

		// bottom threshold check
		if ( window.pageYOffset > this.state.stickyThreshold.bottom ) {
			this.toggleTop( false );
			this.toggleBottom( true );
			this.toggleSticky( false );
		}
		// Top threshold check
		else if ( window.pageYOffset > this.state.stickyThreshold.top ) {
			this.toggleTop( false );
			this.toggleSticky( true );
			this.toggleBottom( false );
		}
		else {
			this.toggleSticky( false );
			this.toggleBottom( false );
			this.toggleTop( true );
		}
	}

	getNextSectionIdx() {
		let nextIdx = false;
		let i = this.state.sections.length;
		const scrollYPos = window.pageYOffset + window.innerHeight / 2;

		do {
			i -= 1;
			const sct = this.state.sections[ i ];

			if ( ( typeof sct !== 'undefined' && scrollYPos > sct.start ) || i === 0 ) {
				nextIdx = i;
			}
		} while ( nextIdx === false );

		return nextIdx;
	}

	isHidden() {
		const scrollYPos = Math.floor(
			window.pageYOffset + ( window.innerHeight / 2 ) + ( this.state.elementHeight / 2 )
		);
		return !! this.state.hiderSections.find( ( hdrs ) => (
			scrollYPos > hdrs.start && scrollYPos < ( hdrs.end + this.state.elementHeight )
		) );
	}

	updateSizes() {
		const rect = this.element.getBoundingClientRect();
		const halhElH = rect.height / 2;
		const halfVH = window.innerHeight / 2;

		this.state.elementHeight = rect.height;

		this.state.topTranslate = window.innerHeight - this.refs.header.clientHeight;
		this.state.bottomHeight = this.refs.bottom.clientHeight;

		this.state.stickyThreshold.top = Math.floor( ( halfVH - halhElH ) - this.state.topTranslate );
		this.state.stickyThreshold.bottom = Math.floor( this.refs.bottom.offsetTop - halfVH - halhElH );
	}

	updateSectionThresholds() {
		const lng = this.refs.breakers.length;

		this.state.sections = this.refs.breakers.map( ( brk, i ) => {
			const nextBrk = ( i < lng - 1 ) ? this.refs.breakers[ i + 1 ] : null;

			return {
				title: brk.getAttribute( ATTR_BREAKER_TITLE ),
				start: brk.offsetTop + brk.offsetHeight / 2,
				end: ( nextBrk ? nextBrk.offsetTop + nextBrk.offsetHeight / 2 : null ),
			};
		} );
	}

	updateHiderThresholds() {
		this.state.hiderSections = this.refs.hiders.map( ( hdr ) => ( {
			el: hdr,
			start: hdr.offsetTop,
			end: hdr.offsetTop + hdr.offsetHeight,
		} ) );
	}

	// #region DOM UPDATE
	toggleTop( force = true ) {
		this.element.classList[ force ? 'add' : 'remove' ]( CLASS_IS_TOP );
		this.element.style.transform = ( force ? `translate3d(0,-100%,0) rotate(-90deg) translate3d(${ this.state.topTranslate }px, 100%,0)` : null );
	}

	toggleSticky( force = true ) {
		this.element.classList[ force ? 'add' : 'remove' ]( CLASS_IS_STICKY );
	}

	toggleBottom( force = true ) {
		this.element.classList[ force ? 'add' : 'remove' ]( CLASS_IS_BOTTOM );
		this.element.style.transform = ( force ? `translate3d(0,-100%,0) rotate(-90deg) translate3d(${ this.state.bottomHeight }px, 100%,0)` : null );
	}

	toggleHide( force = true ) {
		this.element.classList[ force ? 'add' : 'remove' ]( CLASS_IS_HIDDEN );
	}

	showInitial() {
		if ( ! this.state.isReady ) {
			return;
		}

		this.refs.inner.classList.add( CLASS_SHOW_TO );
		setTimeout( () => {
			this.refs.inner.classList.remove( CLASS_SHOW_ENTER );
			this.refs.inner.classList.remove( CLASS_SHOW_TO );
		}, UPDATE_TITLE_DURATION );
	}

	updateTitle( nextTitleIdx = 0 ) {
		if ( nextTitleIdx === this.state.currentTitle ) {
			return;
		}
		if ( this.state.isUpdating ) {
			return;
		}

		this.state.isUpdating = true;

		// Clone inner, select title element & update the title text
		const nextElInner = this.refs.inner.cloneNode( true );
		nextElInner.classList.add( CLASS_SHOW_ENTER );
		nextElInner.lastElementChild.innerHTML = this.state.sections[ nextTitleIdx ].title;

		// Start hiding animation
		this.refs.inner.classList.add( CLASS_HIDE_ENTER );
		setTimeout( () => {
			this.refs.inner.classList.add( CLASS_HIDE_TO );
			this.element.insertAdjacentElement( 'afterbegin', nextElInner );

			setTimeout( () => {
				nextElInner.classList.add( CLASS_SHOW_TO );

				setTimeout( () => {
					this.updateSizes();
					this.element.removeChild( this.refs.inner );

					nextElInner.classList.remove( CLASS_SHOW_ENTER );
					nextElInner.classList.remove( CLASS_SHOW_TO );

					this.refs.inner = nextElInner;

					this.state.isUpdating = false;
					this.state.currentTitle = nextTitleIdx;
				}, UPDATE_TITLE_DURATION );
			}, 10 );
		}, 10 );
	}

	// #endregion
}
