import { throttle } from 'lodash-es';

const THRESHOLD = 100; // px
const TRHOTTLE_DELAY = 100; // ms

const SMALL_CLASS = 'nav--small';

let navigationView = null;

function setSmall() {
	navigationView.element.classList.add( SMALL_CLASS );
}

function unsetSmall() {
	navigationView.element.classList.remove( SMALL_CLASS );
}

/**
 * If page is scrolled, shrink the menu
 * Else, reset default height
 */
function update() {
	if ( window.pageYOffset > THRESHOLD ) {
		setSmall();
	}
	else {
		unsetSmall();
	}
}

const onScroll = throttle( update, TRHOTTLE_DELAY );

function bind() {
	window.addEventListener( 'scroll', onScroll );
	document.addEventListener( 'barba:start', unsetSmall );
}

function destroy() {
	window.removeEventListener( 'scroll', onScroll );
	document.removeEventListener( 'barba:start', unsetSmall );
	navigationView = null;
}

export default function init( view = null ) {
	if ( view === null ) {
		return false;
	}

	navigationView = view;

	bind();
	update();

	return {
		update,
		destroy,
	};
}
