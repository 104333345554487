import delegate from 'delegate';
import {
	isFunction,
	isEmpty,
	isBoolean,
	pullAt,
} from 'lodash-es';

/**
 * Generate an incremental
 * view id.
 */
let VIEW_ID = 0;
function generateViewId() {
	VIEW_ID += 1;
	return VIEW_ID;
}

/**
 * Base view class.
 * All the components should extend this class.
 *
 * @class BaseView
 */
export default class BaseView {
	/**
	 * Constructor.
	 *
	 * @param {Element} element dom element to bind on
	 * @param {boolean} global lifespan: page or app?
	 */
	constructor( element, global ) {
		this.element	= element;
		this.global		= global;
		this.channel	= document;
		this.vid			= generateViewId();
		this.events		= {
			global: {},
			element: {},
		};
	}

	/**
	 * Default initialize method.
	 */
	initialize() {}

	/**
	 * Return the events attached to the element & channel.
	 *
	 * @return {Object} the events object
	 */
	getEvents() {
		return this.events;
	}

	/**
	 * Main bind function.
	 * Trigger all the code on the view.
	 */
	bind() {
		// this.delegateEvents();
		this.initialize();
	}

	/**
	 * Get a scoped element.
	 *
	 * @param {string} selector
	 * @return {Node} A non-lice Node of element object
	 */
	getScopedElement( selector ) {
		return this.element.querySelector( selector );
	}

	/**
	 * Get a scoped collection of elements.
	 *
	 * @param {string} selector
	 * @return {NodeList}			A non-live NodeList of element objects.
	 */
	getScopedElements( selector ) {
		return this.element.querySelectorAll( selector );
	}

	/**
	 * Is desktop?
	 */
	isDesktop() {
		if ( window && window.innerWidth ) {
			return ( window.innerWidth >= 1024 );
		}
		return true;
	}

	/**
	 * Trigger a global event.
	 *
	 * @param {string}  eventName
	 * @param {Object}  params
	 * @param {boolean} global
	 */
	trigger( eventName, params = null, global = true ) {
		const event = new CustomEvent( eventName, params );
		if ( global ) {
			this.channel.dispatchEvent( event );
		}
		else {
			this.element.dispatchEvent( event );
		}
	}

	/**
	 * Bind a callback to an element event or global event.
	 *
	 * @param {string} eventName
	 * @param {string} selector Optional.
	 * @param {Function} handler
	 * @param {boolean} channel Optional. Default: false. Whether or not to bind a global event.
	 * @param {*} options
	 */
	on( eventName, selector, handler, channel = false, options = null ) {
		// Handle the optional selector argument
		if ( isFunction( selector ) ) {
			channel = handler || false;
			handler = selector;
			selector = null;
		}

		if ( channel ) {
			if ( ! ( eventName in this.events.global ) ) {
				this.events.global[ eventName ] = [];
			}

			this.channel.addEventListener( eventName, handler, options );
			this.events.global[ eventName ].push( handler );
		}
		else {
			if ( ! ( eventName in this.events.element ) ) {
				this.events.element[ eventName ] = [];
			}

			if ( isEmpty( selector ) ) {
				this.element.addEventListener( eventName, handler, options );
				this.events.element[ eventName ].push( {
					selector: 'self',
					handler,
					options,
				} );
			}
			else {
				this.events.element[ eventName ].push( {
					selector,
					handler,
					delegate: delegate( this.element, selector, eventName, ( e ) => {
						handler( e );
					}, ( options === null ? true : options ) ),
				} );
			}
		}
	}

	/**
	 * Unbind the given element event or global event.
	 *
	 * @param {string}		eventName
	 * @param {string}		selector		Optional.
	 * @param {boolean}		channel			Optional. Default: false.
	 *                                Whether or not to unbind a global event.
	 */
	off( eventName, selector = null, channel = false ) {
		// Handle the optional selector argument
		if ( isBoolean( selector ) ) {
			channel = selector;
			selector = null;
		}

		if ( channel ) {
			if ( eventName in this.events.global ) {
				for ( let i = 0; i < this.events.global[ eventName ].length; i++ ) {
					this.channel.removeEventListener( eventName, this.events.global[ eventName ][ i ] );
				}
			}
			delete this.events.global[ eventName ];
		}
		else if ( eventName in this.events.element ) {
			if ( isEmpty( selector ) ) {
				// Events binded to the element
				for ( let i = this.events.element[ eventName ].length - 1; i >= 0; i-- ) {
					if ( this.events.element[ eventName ][ i ].selector === 'self' ) {
						const evt = this.events.element[ eventName ][ i ];
						this.element.removeEventListener( eventName, evt.handler, evt.option );
					}
					else {
						this.events.element[ eventName ][ i ].delegate.destroy();
					}
				}
				delete this.events.element[ eventName ];
			}
			else {
				// unbind delegates events
				for ( let i = this.events.element[ eventName ].length - 1; i >= 0; i-- ) {
					if ( selector === this.events.element[ eventName ][ i ].selector ) {
						this.events.element[ eventName ][ i ].delegate.destroy();
						pullAt( this.events.element[ eventName ], i );
					}
				}
			}
		}
	}

	/**
	 * Off all events (element & global).
	 */
	destroy() {
		Object.keys( this.events.global ).forEach( ( eventName ) => {
			this.off( eventName, null, null, true );
		} );
		Object.keys( this.events.element ).forEach( ( eventName ) => {
			this.off( eventName, null, null, true );
		} );

		// Remove listeners attached to the viewport service
		viewport_service.removeAllListeners();
	}
}
