import BaseView from '../../../js/base-view';

const OPEN_CLASS = 'is-open';
const ACTIVE_CLASS = 'is-active';
const TRIGGER_SELECTOR = '[data-trigger-toolbox]';
const BACKDROP_SELECTOR = '.toolbox-backdrop';
const SELECTOR_ITEM = '.toolbox__item';

export default class Toolbox extends BaseView {
	init() {
		// States
		this.state = new Proxy( { isOpen: false }, { set: this.stateChange.bind( this ) } );

		// DOM element reference
		this.refs = {
			trigger: document.querySelector( TRIGGER_SELECTOR ),
			backdrop: document.querySelector( BACKDROP_SELECTOR ),
		};
	}

	stateChange( state, property, value ) {
		// eslint-disable-next-line no-param-reassign
		state[ property ] = value;

		// eslint-disable-next-line default-case
		switch ( property ) {
			case 'isOpen':
				this.element.classList[ state.isOpen ? 'add' : 'remove' ]( OPEN_CLASS );
				this.refs.trigger.classList[ state.isOpen ? 'add' : 'remove' ]( ACTIVE_CLASS );
				break;
		}

		return true;
	}

	bind() {
		this.init();

		if ( this.refs.trigger === null ) {
			return;
		}

		this.toggleHandler = this.toggle.bind( this );
		this.refs.trigger.addEventListener( 'click', this.toggleHandler );
		this.refs.backdrop.addEventListener( 'click', this.toggleHandler );

		this.on( 'click', SELECTOR_ITEM, this.onItemClick.bind( this ) );
	}

	toggle( event ) {
		event.preventDefault();
		this.state.isOpen = ! this.state.isOpen;
	}

	onItemClick() {
		this.state.isOpen = false;
	}

	destroy() {
		if ( this.refs.trigger ) {
			this.refs.trigger.removeEventListener( 'click', this.toggleHandler );
		}

		if ( this.refs.backdrop ) {
			this.refs.backdrop.removeEventListener( 'click', this.toggleHandler );
		}

		super.destroy();
	}
}
