import { isEmpty } from 'lodash-es';

import BaseView from '../../../js/base-view';
import requester from '../../../js/utils/requester';

import AlphaTabs from '../../molecules/alphabetical-tabs/alphabetical-tabs';
import SearchBar from '../../organisms/search-bar/search-bar';
import ResultList from '../../organisms/result-list/result-list';

const SELECTOR_SEARCH_BAR = '[data-search-bar]';
const SELECTOR_ALPHABETICAL_TABS = '[data-alphabetical-tabs]';
const SELECTOR_RESULT_LIST = '[data-result-list]';

const DEFAULT_CONFIG = {
	elasticHost: 'http://localhost:9200',
	index: 'profiles',
	nbPerPage: 12,
	baseUrlProfile: 'http://localhost/profile',
	avatarImgURL: '/assets/img/profile-avatar.svg',
	lang: 'fr',
	ot: null, // "ot" for "Auth",
	profileImg: {
		uri: false,
		ext: 'jpg',
	},
};

const DEFAULT_STATE = {
	page: 1,
	letter: null,
	searchValue: null,
	searchFilter: null,
	showNbResults: false,
};

const SUGGEST_ARGS = {
	name: 'fullname.suggest',
	firstname: 'full_firstname.suggest',
	position: 'position.suggest',
	departments: 'departments.name.suggest',
	skills: `skills.${ ( ( window.supt && window.supt.lang ) ? window.supt.lang : DEFAULT_CONFIG.lang ) }.suggest`,
	modules: `modules.${ ( ( window.supt && window.supt.lang ) ? window.supt.lang : DEFAULT_CONFIG.lang ) }.suggest`,
};

export default class PageAnnuaire extends BaseView {
	// #######################
	// #region Init
	// #######################

	initialize() {
		// fetch config from the global scope
		// NOTE: we do this because we need to pass json data from views (for the "query" parameter),
		//       and it's quite tricky and error-prone to pass it through html data-attributes
		//       (mostly because escaping is not consistent between js/php)
		if ( ! window.supt || ! window.supt.directory ) {
			console.error( 'Could not load config' );
			return false;
		}

		this.config = { ...DEFAULT_CONFIG, ...window.supt.directory };
		if ( window.supt && window.supt.lang ) {
			this.config.lang = window.supt.lang;
		}

		this.renderCard = this.renderCard.bind( this );

		const listEl = this.element.querySelector( SELECTOR_RESULT_LIST );
		this.refs = {
			searchBar: new SearchBar( this.element.querySelector( SELECTOR_SEARCH_BAR ) ).init(),
			alphaTabs: new AlphaTabs( this.element.querySelector( SELECTOR_ALPHABETICAL_TABS ) ).init(),
			profileList: new ResultList( listEl ).init( { renderCard: this.renderCard } ),
		};

		// We force clone DEFAULT_STATE with JSON parse/stringify
		// because it was making a shallow-copy instead of a clone
		this.state = new Proxy(
			JSON.parse( JSON.stringify( DEFAULT_STATE ) ),
			{ set: this.stateChange.bind( this ) },
		);

		this.bindEvents();

		this.query();

		return true;
	}

	/**
	 * Trap handler for the state object
	 *
	 * @param {Object} state Current state object
	 * @param {string} property The name of the property to set in the state
	 * @param {*} value The new value of the property to set
	 *
	 * @return {boolean} Indicate wether or not the assignment succeeded
	 */
	stateChange( state, property, value ) {
		/* eslint-disable no-param-reassign */
		if ( state[ property ] === value ) {
			return true;
		}
		state[ property ] = value;

		switch ( property ) {
			case 'letter':
				state.page = 1;
				break;

			case 'searchValue':
				state.page = 1;
				state.letter = null;
				state.searchFilter = null;
				break;

			case 'searchFilter':
				state.page = 1;
				state.letter = null;
				break;

			case 'page':
			default:
				// nothing to do
				break;
		}
		return true;
	}

	bindEvents() {
		this.on( 'search:change', SELECTOR_SEARCH_BAR, this.onSearchChange.bind( this ) );
		this.on( 'search:submit', SELECTOR_SEARCH_BAR, this.onSubmit.bind( this ) );

		this.on( 'change', SELECTOR_ALPHABETICAL_TABS, this.onLetterChange.bind( this ) );

		this.on( 'next-page', SELECTOR_RESULT_LIST, this.onNextPage.bind( this ) );
	}

	destroy() {
		this.refs.searchBar.destroy();
		this.refs.alphaTabs.destroy();
		this.refs.profileList.destroy();

		super.destroy();
	}

	// #######################
	// #endregion
	// #######################

	// #######################
	// #region Event Handlers
	// #######################

	onSearchChange( { detail } ) {
		this.state.searchValue = detail;

		this.suggest();
	}

	onSubmit( { detail } ) {
		this.state.searchValue = detail.text;
		this.state.searchFilter = detail.filter;

		this.query();
	}

	onLetterChange( { detail } ) {
		this.state.letter = detail;

		this.query();
	}

	onNextPage() {
		this.state.page += 1;

		this.query();
	}

	// #######################
	// #endregion
	// #######################

	// #######################
	// #region ACTIONS
	// #######################

	suggest() {
		const suggest = {};
		Object.keys( SUGGEST_ARGS ).forEach( ( key ) => {
			suggest[ key ] = {
				prefix: this.state.searchValue,
				completion: {
					field: SUGGEST_ARGS[ key ],
					skip_duplicates: true,
					fuzzy: {
						fuzziness: 2,
					},
				},
			};
		} );

		let auth = null;
		if ( ! isEmpty( this.config.ot ) ) {
			auth = {
				username: this.config.ot.u,
				password: this.config.ot.p,
			};
		}

		requester( {
			name: 'pageAnnuaire:suggest',
			url: `${ this.config.elasticHost }/${ this.config.index }/_search`,
			method: 'post',
			auth,
			data: {
				query: this.buildQuery(),
				suggest,
				size: this.config.nbPerPage * 2,
				from: 0,
			},
		} )
			.then( ( { data } ) => {
				this.refs.searchBar.updateInputNbResults( data.hits.total.value );

				const nowDate = new Date();
				nowDate.setHours( 0, 0, 0, 0 ); // reset to midnight
				const todayTime = nowDate.getTime();

				const suggestions = Object.keys( SUGGEST_ARGS ).map( ( key ) => {
					const filterCatName = this.refs.searchBar.getCategoryTitle( key );

					return data.suggest[ key ][ 0 ].options
						.filter( ( { _source: source } ) => {
							if ( ! source.end_contract ) {
								return true;
							}
							return ( new Date( source.end_contract ).getTime() > todayTime );
						} )
						.map( ( { text, _source: source } ) => ( {
							value: text,
							category: filterCatName,
							score: source,
						} ) );
				} ).flat().sort( ( a, b ) => b.score - a.score );

				this.refs.searchBar.updateInputSuggestions( suggestions );
			} );
	}

	query() {
		const query = this.buildQuery();
		const sort = [
			{ name: 'asc' },
		];

		// Do not sort by _score if no search nor filters
		let filters = null;
		if ( ! isEmpty( this.state.searchFilter ) ) {
			filters = Object.keys( this.state.searchFilter )
				.filter( ( k ) => ! isEmpty( this.state.searchFilter[ k ] ) )
				.map( () => true );
		}

		if ( ! isEmpty( this.state.searchValue ) || ! isEmpty( filters ) ) {
			sort.unshift( '_score' );
		}

		const fromIndex = this.config.nbPerPage * ( this.state.page - 1 );

		const args = {
			name: 'pageAnnuaire:query',
			url: `${ this.config.elasticHost }/${ this.config.index }/_search`,
			method: 'post',
			auth: this.config.ot,
			data: {
				query,
				size: this.config.nbPerPage,
				from: fromIndex,
				sort,
				aggregations: {
					letters: {
						terms: {
							size: 26,
							field: 'letter',
						},
					},
					departments: {
						terms: {
							field: 'departments.name.keyword',
							size: 1000,
						},
					},
					modules: {
						terms: {
							field: `modules.${ this.config.lang }.keyword`,
							size: 1000,
						},
					},
					skills: {
						terms: {
							field: `skills.${ this.config.lang }.keyword`,
							size: 1000,
						},
					},
				},
			},
		};

		if ( ! isEmpty( this.config.ot ) ) {
			args.auth = {
				username: this.config.ot.u,
				password: this.config.ot.p,
			};
		}

		requester( args )
			.then( ( { data } ) => {
				const results = data.hits.hits.map( this.getCardContext.bind( this ) );

				const hasMoreItemsToLoad = ( fromIndex + results.length < data.hits.total.value );

				this.refs.profileList[ ( this.state.page > 1 ? 'addPage' : 'setList' ) ]( results, ! hasMoreItemsToLoad );

				this.refs.searchBar
					.updateInputNbResults( this.state.showNbResults ? data.hits.total.value : 0 );

				if ( this.state.letter === null ) {
					this.refs.alphaTabs.setEnabledLetters(
						data.aggregations.letters.buckets.map( ( bucket ) => bucket.key ),
					);

					this.refs.searchBar.updateFilters( {
						departments: data.aggregations.departments.buckets.map( ( bucket ) => bucket.key ),
						modules: data.aggregations.modules.buckets.map( ( bucket ) => bucket.key ),
						skills: data.aggregations.skills.buckets.map( ( bucket ) => bucket.key ),
					} );
				}
			} );
	}

	buildQuery() {
		// Empty
		const queryArgs = [];

		// Exclude profiles with no contract
		queryArgs.push( {
			bool: {
				should: [
					{ range: { end_contract: { gte: 'now' } } },
					{ bool: { must_not: { exists: { field: 'end_contract' } } } },
				],
			},
		} );

		// search text
		if ( ! isEmpty( this.state.searchValue ) ) {
			queryArgs.push( {
				bool: {
					should: [
						{ match: { fullname: { query: this.state.searchValue, fuzziness: 1 } } },
						{ match: { position: { query: this.state.searchValue, fuzziness: 1 } } },
						{ match: { 'department.name': { query: this.state.searchValue, fuzziness: 1 } } },
						{ match: { [ `skills.${ this.config.lang }` ]: { query: this.state.searchValue, fuzziness: 1 } } },
						{ match: { [ `modules.${ this.config.lang }` ]: { query: this.state.searchValue, fuzziness: 1 } } },
					],
				},
			} );
		}

		// Filters selected
		const terms = this.getTerms();
		if ( terms !== null ) {
			terms.forEach( ( t ) => queryArgs.push( t ) );
		}

		// name starts with letter X
		if ( ! isEmpty( this.state.letter ) ) {
			queryArgs.push( { match: { letter: this.state.letter } } );
		}

		// Update the showNbResults state
		// I cannot set this inside `stateChange` because state.searchFilter is an object
		// and the proxy is not triggered by sub-objects
		this.state.showNbResults = ! ( isEmpty( this.state.searchValue ) && isEmpty( terms ) );

		return {
			bool: {
				must: queryArgs,
			},
		};
	}

	getTerms() {
		let terms = null;
		if ( ! isEmpty( this.state.searchFilter ) ) {
			terms = Object.keys( this.state.searchFilter )
				.filter( ( name ) => ! isEmpty( this.state.searchFilter[ name ] ) )
				.map( ( name ) => {
					const termfieldName = (
						name === 'departments' ? 'departments.name.keyword' : `${ name }.${ this.config.lang }.keyword`
					);

					return {
						terms: {
							[ termfieldName ]: this.state.searchFilter[ name ],
						},
					};
				} );
		}

		return terms;
	}

	getCardContext( { _source: source } ) {
		let img = false;

		if ( source.idisa && ! isEmpty( this.config.profileImg.uri ) ) {
			img = { src: `${ this.config.profileImg.uri }/${ source.idisa }.${ this.config.profileImg.ext }` };
		}

		return {
			modifiers: [],
			data: {
				name: source.firstname,
				surname: source.name,
				link: ( typeof source.email === 'string'
					? `${ this.config.baseUrlProfile }/${ source.email.split( '@' )[ 0 ] }/`
					: false
				),
				description: source.position,
				avatar: this.config.avatarImgURL,
				img,
			},
		};
	}

	renderCard( { modifiers = [], data = {} } ) {
		const name = `<span class="card-profile__surname">${ data.surname }</span><span>${ data.name }</span>`;
		const hlevel = `h${ data?.level || 2 }`;
		return `
		<article class="card-profile ${ modifiers.join( ' ' ) }">
			<div class="card-profile__inner">
				<div class="card-profile__img">
					<img src="${ this.config.avatarImgURL }" />
					<img class="is-hidden" src="${ data.img.src }" />
				</div>
				${ data.link !== false ? '<svg class="card-profile__btn"><use href="#expand" /></svg>' : '' }
				<div class="card-profile__content">
					<${ hlevel } class="card-profile__title">
						${ data.link !== false ? `<a href="${ data.link }">${ name }</a>` : name }
					</${ hlevel }>
					<p class="card-profile__description">${ data.description }</p>
				</div>
			</div>
		</article>`;
	}

	// #######################
	// #endregion
	// #######################
}
