// console.log('JavaScript is awesome at superhuit!');

/**
 * Load assets & Styles
 */
import './assets/img';
import './assets/icons';

import './main.scss';

import componentsConfig from './components/_loader';
import componentsManager from './js/utils/components-manager';
import barbaManager from './js/utils/barba-manager';

/**
 * Services etc
 */
import './js/services/viewport';
import './js/services/window-scrollbar';

/**
 * Components loader
 */
componentsManager.load( componentsConfig );

/**
 * Barba (page transitions)
 */
if ( ! document.body.classList.contains( 'admin-bar' ) ) {
	barbaManager( componentsConfig.filter( ( c ) => ! c.global ) );
}
// First time we load the page, we need to trigger the animations
setTimeout( () => {
	document.dispatchEvent( new CustomEvent( 'appearance:start' ) );
	document.dispatchEvent( new CustomEvent( 'header:play-animation' ) );
}, 100 );
