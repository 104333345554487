import BaseView from '../../../js/base-view';

const CLASS_OPEN = 'is-expanded';

const SELECTOR_TOGGLE = '[data-accordeon-toggle]';

export default class AccordeonItem extends BaseView {
	init() {
		this.state = {
			isOpen: this.element.classList.contains( CLASS_OPEN ),
		};

		this.refs = {
			toggle: this.element.querySelector( SELECTOR_TOGGLE ),
		};

		this.bindEvents();
		return this;
	}

	bindEvents() {
		this.onClickHandler = this.onClick.bind( this );
		this.refs.toggle.addEventListener( 'click', this.onClickHandler );
	}

	onClick( event ) {
		event.stopPropagation();
		this.toggle();
	}

	toggle() {
		if ( this.state.isOpen ) {
			this.close();
		}
		else {
			this.open();
		}
	}

	open() {
		this.element.classList.add( CLASS_OPEN );
		this.state.isOpen = true;
		this.trigger( 'open', { bubbles: true }, false );
	}

	close() {
		this.element.classList.remove( CLASS_OPEN );
		this.state.isOpen = false;
		this.trigger( 'close', null, false );
	}

	destroy() {
		this.refs.toggle.removeEventListener( 'click', this.onClickHandler );
	}
}
