import BaseView from '../../../js/base-view';

const CLASS_IS_OPEN = 'is-open';
const CLASS_IS_VISIBLE = 'is-visible';
const CLASS_IS_DISABLED = 'is-disabled';

const SELECTOR_FILTER_ITEM = '.search-filter__item';
const SELECTOR_FILTER_CATEGORY = '.search-filter__category';
const SELECTOR_FILTER_BADGE = '.search-filter__badge';
const SELECTOR_FILTER_TITLE = '.search-filter__category__title';
const SELECTOR_OPTION = '.search-filter__options__item';
const SELECTOR_OPTION_INPUT = '.input__field';
const SELECTOR_OPTION_RESET = '.search-filter__options__reset';
const SELECTOR_OPTION_VALIDATE = '.search-filter__options__validate';

export default class SearchFilter extends BaseView {
	init() {
		const cats = Array.from( this.element.querySelectorAll( SELECTOR_FILTER_ITEM ) );
		this.refs = {
			categories: cats,
			badges: cats.map( ( cat ) => cat.querySelector( SELECTOR_FILTER_BADGE ) ),
			options: cats.map( ( cat ) => Array.from( cat.querySelectorAll( SELECTOR_OPTION_INPUT ) ) ),
		};

		this.props = {
			categories: cats.map( ( cat ) => cat.getAttribute( 'data-filter-category' ) ),
			categoriesTitle: cats.map( ( cat ) => cat.querySelector( SELECTOR_FILTER_TITLE ).textContent ),
			options: this.refs.options.map( ( cat ) => cat.map( ( opt ) => opt.value ) ),
		};

		this.state = {
			active: false,
			data: {},
		};
		cats.forEach( ( cat, i ) => {
			this.state.data[ this.props.categories[ i ] ] = [];
		} );

		this.bindEvents();

		window.searchFilter = this;
		return this;
	}

	bindEvents() {
		this.on( 'click', SELECTOR_FILTER_CATEGORY, this.onCategoryClick.bind( this ) );
		this.on( 'click', SELECTOR_OPTION_RESET, this.onReset.bind( this ) );
		this.on( 'click', SELECTOR_OPTION_VALIDATE, this.onValidate.bind( this ) );

		this.onClickOutHandler = this.onClickOut.bind( this );
		document.addEventListener( 'click', this.onClickOutHandler );
	}

	onCategoryClick( event ) {
		event.preventDefault();
		const idx = this.refs.categories.indexOf( event.target.parentElement );
		this.toggleCategory( idx );
	}

	onReset( event ) {
		const idx = this.refs.categories.indexOf( event.target.closest( SELECTOR_FILTER_ITEM ) );
		this.resetFilter( idx );
	}

	onValidate( event ) {
		const idx = this.refs.categories.indexOf( event.target.closest( SELECTOR_FILTER_ITEM ) );
		this.validateFilter( idx );
		this.toggleCategory(); // close filter
		this.trigger( 'filter:change', { detail: this.state.data }, false );
	}

	onClickOut( event ) {
		if ( this.state.active !== null && ! this.element.contains( event.target ) ) {
			this.toggleCategory();
		}
	}

	validateFilter( idx ) {
		const catName = this.props.categories[ idx ];
		const options = this.refs.options[ idx ];

		this.state.data[ catName ] = options
			.filter( ( opt ) => opt.checked ).map( ( opt ) => opt.value );
		this.toggleBadge( this.refs.badges[ idx ], this.state.data[ catName ].length );
	}

	resetFilter( idx ) {
		const catName = this.props.categories[ idx ];
		this.state.data[ catName ] = [];

		this.toggleBadge( this.refs.badges[ idx ], 0 );
		this.refs.options[ idx ].forEach( ( opt ) => {
			// eslint-disable-next-line no-param-reassign
			opt.checked = false;
		} );

		this.trigger( 'filter:change', { detail: this.state.data }, false );
	}

	/**
	 *  handles filter category opening
	 *
	 * @param {number} categoryIdx
	 */
	toggleCategory( categoryIdx = false ) {
		// Close any other category if open
		if ( this.state.active !== false ) {
			this.refs.categories[ this.state.active ].classList.remove( CLASS_IS_OPEN );
		}

		if ( this.state.active === categoryIdx ) {
			this.state.active = false;
			return;
		}

		// Open category
		if (
			categoryIdx !== false &&
			categoryIdx >= 0 &&
			categoryIdx < this.refs.categories.length
		) {
			this.refs.categories[ categoryIdx ].classList.add( CLASS_IS_OPEN );
		}

		this.state.active = categoryIdx;
	}

	// ########################
	// #region DOM MANIPULATION
	// ########################

	toggleBadge( badge, number = 0 ) {
		/* eslint-disable no-param-reassign */
		badge.textContent = number;
		badge.classList[ ( number > 0 ? 'add' : 'remove' ) ]( CLASS_IS_VISIBLE );
	}

	enableOptions() {
		this.refs.options.forEach( ( catOpts ) => {
			catOpts.forEach( ( opt ) => {
				opt.closest( SELECTOR_OPTION ).classList.remove( CLASS_IS_DISABLED );
			} );
		} );
	}

	resetOptions() {
		this.refs.options.forEach( ( catOpts ) => {
			catOpts.forEach( ( opt ) => {
				opt.checked = false;
				opt.closest( SELECTOR_OPTION ).classList.remove( CLASS_IS_DISABLED );
			} );
		} );
	}

	// ########################
	// #endregion
	// ########################

	// ########################
	// #region PUBLIC METHODS
	// ########################

	/**
	 *
	 * @param {Object} enabledOptsPerCat Object with category name as key
	 *                                   and array of enabled options as value
	 */
	updateEnabledOptions( enabledOptsPerCat = {} ) {
		this.enableOptions();
		Object.keys( enabledOptsPerCat ).forEach( ( catName ) => {
			const catIdx = this.props.categories.indexOf( catName );
			if ( catIdx >= 0 ) {
				const enabledOpts = enabledOptsPerCat[ catName ];

				this.refs.options[ catIdx ].forEach( ( opt ) => {
					if ( enabledOpts.indexOf( opt.value ) === -1 ) {
						opt.checked = false;
						opt.closest( SELECTOR_OPTION ).classList.add( CLASS_IS_DISABLED );
					}
				} );
			}
		} );
	}

	updateActiveOptions( name = '' ) {
		this.props.options.forEach( ( cat, catIdx ) => {
			cat.forEach( ( optVal, optIdx ) => {
				this.refs.options[ catIdx ][ optIdx ].checked = ( optVal === name );
			} );
			this.validateFilter( catIdx );
		} );

		return this.state.data;
	}

	reset() {
		this.resetOptions();
		this.refs.badges.forEach( ( badge ) => this.toggleBadge( badge, 0 ) );
	}

	getCategoryTitle( name ) {
		return this.props.categoriesTitle[
			this.props.categories.findIndex( ( cat ) => cat === name )
		];
	}

	// ########################
	// #endregion
	// ########################

	destroy() {
		document.removeEventListener( 'click', this.onClickOutHandler );
		super.destroy();
	}
}
