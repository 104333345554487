import BaseView from '../../../js/base-view';

const CLASS_INITIAL_APPEARANCE = 'initial-appearance';
const CLASS_APPEAR = 'appear-initial';

/**
 * Find the largest timing number from
 * a seconds based string in CSS format
 * i.e. '0.4s, 0.3s, 0.9s' => 0.9
 *
 * @param {string} strTiming The timing number string
 * @return {number} The largest number
 */
function findLargestTiming( strTiming ) {
	return Math.max(
		...strTiming.split( /,\s?/ ).map( ( timing ) => parseFloat( timing ) ),
	);
}

export default class InitialAppearance extends BaseView {
	bind() {
		const styles = window.getComputedStyle( this.element );

		this.props = {
			delay: findLargestTiming( styles.transitionDelay ),
			duration: findLargestTiming( styles.transitionDuration ),
		};

		this.appearanceStartHandler = this.onAppearanceStart.bind( this );
		this.on( 'appearance:start', this.appearanceStartHandler, true );
		this.on( 'transition:end', this.appearanceStartHandler, true );
	}

	onAppearanceStart() {
		this.element.classList.add( CLASS_APPEAR );
		setTimeout(
			this.onAppearanceEnd.bind( this ),
			( this.props.delay + this.props.duration ) * 1000
		);
	}

	onAppearanceEnd() {
		this.element.classList.remove( CLASS_INITIAL_APPEARANCE );
		this.element.classList.remove( CLASS_APPEAR );
		this.trigger( 'initial-appearance:end' );
		// this.destroy();
	}
}
