/**
 * Transform an object into a query string that can be used for a request payload
 * i.e. transforms {rap: 'yo', rap2: 'yo2'} into 'rap=yo&rap2=yo2'
 *
 * @param {Object} obj the parameters you want to encode
 * @param {string} prefix The parent object key
 *
 * @source https://stackoverflow.com/a/1714899/5078169
 */
export default function objectToQueryString( obj, prefix ) {
	return Object.keys( obj ).map( ( key ) => {
		const k = prefix ? `${ prefix }[${ key }]` : key;
		const v = obj[ key ];
		return ( ( v !== null && typeof v === 'object' )
			? objectToQueryString( v, k )
			: `${ encodeURIComponent( k ) }=${ encodeURIComponent( v ) }` );
	} ).join( '&' );
}
