import TimelineLite from 'gsap/TimelineLite';
import CSSPlugin from 'gsap/CSSPlugin';
import BaseView from '../../../js/base-view';

// import { SplitText } from '../../../js/utils/gsap/SplitText';
import { CustomEase } from '../../../js/utils/gsap/CustomEase';

import HeaderVideo from './header--video';

// hack to avoid treeshaking of gsap plugins
// eslint-disable-next-line no-unused-vars
const plugins = [ CSSPlugin ];

// deps settings
CSSPlugin.defaultForce3D = true;

const CLASS_ANIMATING = 'is-animating';

const UPTITLE_SELECTOR = '[data-anim-uptitle]';
const TITLE_SELECTOR = '.header__title';
const MEDIA_SELECTOR = '.header__media,.header__img';
const MEDIA_WRAP_SELECTOR = '.header__media-wrap,.header__img-wrap';
const MEDIA_MASK_SELECTOR = '.header__media-mask';
const DESC_SELECTOR = '.header__description';
const DETAILS_SELECTOR = '[data-anim-details]';
const COVER_IMAGE_SELECTOR = '[data-cover-image]';

export default class Header extends BaseView {
	initialize() {
		this.refs = {
			uptitle: this.getScopedElement( UPTITLE_SELECTOR ),
			title: this.getScopedElement( TITLE_SELECTOR ),
			media: this.getScopedElement( MEDIA_SELECTOR ),
			mediaMask: this.getScopedElement( MEDIA_MASK_SELECTOR ),
			desc: this.getScopedElement( DESC_SELECTOR ),
			details: this.getScopedElement( DETAILS_SELECTOR ),
			coverImage: this.getScopedElement( COVER_IMAGE_SELECTOR ),
		};

		this.state = {
			playReceived: false,
			animationReady: false,
			animations: {},
		};

		this.props = {
			isSmall: ! viewport_service.isComputer(),
			customEase1: CustomEase.create( 'custom-ease-1', '0.36,0,0.03,1' ),
			customEase2: CustomEase.create( 'custom-ease-2', '0.45,0,0.08,1' ),
		};

		this.video = new HeaderVideo( this.getScopedElement( MEDIA_WRAP_SELECTOR ) );

		this.bindEvents();
		this.setAnimationInitial()
			.then( this.setReady.bind( this ) );
	}

	bindEvents() {
		this.onCompleteHandler = this.handleAnimComplete.bind( this );
		this.playAnimationHandler = this.handlePlayAnimation.bind( this );
		this.on( 'header:play-animation', this.playAnimationHandler, true );
		this.on( 'transition:end', this.playAnimationHandler, true );
	}

	handlePlayAnimation() {
		this.state.playReceived = true;
		if ( this.state.animationReady ) {
			this.animate();
		}
	}

	/**
	 * When an animation is completed,
	 * remove the Tween from the state
	 *
	 * @param {string} name Name of the Tween animation
	 */
	handleAnimComplete( name ) {
		delete this.state.animations[ name ];

		if ( Object.keys( this.state.animations ).length === 0 ) {
			this.element.classList.remove( CLASS_ANIMATING );
		}
	}

	/**
	 * Define the initial state of the animation for each DOM element
	 */
	setAnimationInitial() {
		return new Promise( ( resolve ) => {
			if ( this.refs.coverImage ) {
				this.state.animations.coverImage = new TimelineLite( { paused: true } )
					.from( this.refs.coverImage, 1, {
						css: {
							opacity: 0,
						},
						ease: this.props.customEase2,
						delay: 0,
						onComplete: this.onCompleteHandler,
						onCompleteParams: [ 'coverImage' ],
					} );
			}

			if ( this.refs.uptitle ) {
				// Uptitle OPACITY
				this.state.animations.uptitle = new TimelineLite( { paused: true } )
					.from( this.refs.uptitle, 0.5, {
						css: { opacity: 0 },
						delay: 0.150,
						onComplete: this.onCompleteHandler,
						onCompleteParams: [ 'uptitle' ],
					} );
			}

			// Title OPACITY & POSITION
			this.state.animations.titleOpacity = new TimelineLite( { paused: true } )
				.from( this.refs.title, 0.5, {
					css: { opacity: 0 },
					delay: 0.1,
					onComplete: this.onCompleteHandler,
					onCompleteParams: [ 'titleOpacity' ],
				} );
			this.state.animations.titlePosition = new TimelineLite( { paused: true } )
				.from( this.refs.title, 0.75, {
					css: { y: '10vh' },
					ease: this.props.customEase1,
					onComplete: this.onCompleteHandler,
					onCompleteParams: [ 'titlePosition' ],
				} );

			if ( this.refs.media ) {
			// Image + mask OPACITY, POSITION & SCALE
				this.state.animations.media = new TimelineLite( { paused: true } )
					.from( this.refs.media, 0.8, {
						css: {
							y: ( this.props.isMobile ? '10vh' : '20vh' ),
							opacity: ( this.refs.mediaMask ? 1 : 0 ),
						},
						ease: this.props.customEase2,
						delay: 0.15,
						onComplete: this.onCompleteHandler,
						onCompleteParams: [ 'media' ],
					} );

				if ( this.refs.mediaMask ) {
					this.state.animations.mediaMaskPostion = new TimelineLite( { paused: true } )
						.from( this.refs.mediaMask, 0.8, {
							css: { y: ( this.props.isMobile ? '10vh' : '20vh' ) },
							ease: this.props.customEase2,
							delay: 0.15,
							onComplete: this.onCompleteHandler,
							onCompleteParams: [ 'mediaMaskPostion' ],
						} );

					this.state.animations.mediaMaskScale = new TimelineLite( { paused: true } )
						.from( this.refs.mediaMask, 0.8, {
							css: { scaleY: 1, transformOrigin: '0 0' },
							ease: this.props.customEase2,
							delay: 0.15,
							onComplete: this.onCompleteHandler,
							onCompleteParams: [ 'mediaMaskScale' ],
						} );
				}
			}

			if ( this.refs.desc ) {
				this.state.animations.descOpacity = new TimelineLite( { paused: true } )
					.from( this.refs.desc, 0.5, {
						css: { opacity: 0 },
						delay: 0.2,
						onComplete: this.onCompleteHandler,
						onCompleteParams: [ 'descOpacity' ],
					} );
				this.state.animations.descPosition = new TimelineLite( { paused: true } )
					.from( this.refs.desc, 0.75, {
						css: { y: '10vh' },
						ease: this.props.customEase1,
						delay: 0.1,
						onComplete: this.onCompleteHandler,
						onCompleteParams: [ 'descPosition' ],
					} );

			/**
			 * SplitText doesn't support nested tag in the source
			 * There is a work-around listed in the official doc but
			 * It doesn't look like it works, and we don't have now the time to
			 * find  why.
			 *
			 * @see https://greensock.com/forums/topic/18243-split-text-confused/?tab=comments#comment-84134
			 */
			// // Split descriptionby lines & register a Tween per line
			// this.refs.descLines = new SplitText(this.refs.desc, { type: 'lines' });
			// this.refs.descLines.lines.forEach((line, i) => {
			// 	if (i > 0) {
			// 		this.state.animations[`desc-line-${i}`] = TweenLite.from(line, 0.9, {
			// 			css: { y: `${i * 2}vh` },
			// 			ease: this.props.customEase1,
			// 			paused: true,
			// 			onComplete: this.onCompleteHandler,
			// 			onCompleteParams: [`desc-line-${i}`],
			// 			delay: 0.1,
			// 		});
			// 	}
			// });
			}

			if ( this.refs.details ) {
				this.state.animations.detailsOpacity = new TimelineLite( { paused: true } )
					.from( this.refs.details, 0.5, {
						css: { opacity: 0 },
						delay: 0.2,
						onComplete: this.onCompleteHandler,
						onCompleteParams: [ 'detailsOpacity' ],
					} );
				this.state.animations.detailsPosition = new TimelineLite( { paused: true } )
					.from( this.refs.details, 0.75, {
						css: { y: '10vh' },
						ease: this.props.customEase1,
						delay: 0.1,
						onComplete: this.onCompleteHandler,
						onCompleteParams: [ 'detailsPosition' ],
					} );
			}

			// // We wait some milliseconds to be sure the font has loaded and the
			// // Title takes the correct room before to break the separate lines
			// setTimeout(() => {
			// 	// Split the title by lines & register a Tween per line
			// 	this.refs.titleLines = new SplitText(this.refs.title, { type: 'lines' });
			// 	this.refs.titleLines.lines.forEach((line, i) => {
			// 		if (i > 0) {
			// 			this.state.animations[`line-${i}`] = new TimelineLite({ paused: true })
			// 				.from(line, 0.9, {
			// 					css: { y: `${i * 5}vh` },
			// 					ease: this.props.customEase1,
			// 					onComplete: this.onCompleteHandler,
			// 					onCompleteParams: [`line-${i}`],
			// 				});
			// 		}
			// 	});
			resolve();
			// }, 250);
		} );
	}

	/**
	 * Starts each registered animation
	 */
	animate() {
		this.element.classList.add( CLASS_ANIMATING );
		Object.keys( this.state.animations ).forEach( ( name ) => {
			const tl = this.state.animations[ name ];
			tl.play();
		} );

		// Starts the video
		if ( this.video ) {
			this.video.start();
		}
	}

	setReady() {
		this.state.animationReady = true;
		this.element.classList.remove( 'animation-not-ready' );
		this.trigger( 'header:animation-ready' );

		if ( this.state.playReceived ) {
			this.animate();
		}
	}

	destroy() {
		this.video.destroy();
		delete this.video;
		super.destroy();
	}
}
