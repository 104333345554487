import filesize from 'filesize';
import BaseView from '../../../js/base-view';

const VALID_CLASS = 'is-valid';

const FILE_MAX_FILENAME_LENGTH = 32;

const FIELD_SELECTOR = '.input__field';
const FILE_LABEL_SELECTOR = 'label';
const FILE_LABEL_INFO_SELECTOR = '.card__info';
const FILE_LABEL_TITLE_SELECTOR = '.card__title';

const HIDDEN_CLASS = 'is-hidden';

/**
 * @class InputFile
 */
export default class InputFile extends BaseView {
	init() {
		// Select the field
		this.field = this.getScopedElement( FIELD_SELECTOR );

		this.hasFile = false;
		this.hasError = false;

		this.label = this.getScopedElement( FILE_LABEL_SELECTOR );
		this.labelInfo = this.label.querySelector( FILE_LABEL_INFO_SELECTOR );
		this.labelTitle = this.label.querySelector( FILE_LABEL_TITLE_SELECTOR );
		this.labelIcon = this.label.querySelector( FILE_LABEL_TITLE_SELECTOR );

		this.fieldAttrs = this.field.attributes;
		this.initialInfo = this.labelInfo.innerHTML;

		this.bind();
	}

	bind() {
		this.on( 'click', FILE_LABEL_SELECTOR, this.onLabelClick.bind( this ) );
		this.on( 'change', FIELD_SELECTOR, this.onFileChange.bind( this ) );
	}

	onLabelClick( event ) {
		if ( ! this.hasFile ) {
			return;
		}

		event.preventDefault();
		if ( this.hasError ) {
			this.resetError();
		}

		this.deleteFile();
	}

	onFileChange() {
		if ( this.field.files.length === 0 ) {
			return;
		}
		this.resetError();
		this.populateFileLabel( this.field.files[ 0 ] );
	}

	deleteFile() {
		// Destroy field
		this.element.removeChild( this.field );

		// Create new field with all attrs & insert in DOM
		this.field = document.createElement( 'input' );
		for ( let i = 0; i < this.fieldAttrs.length; i++ ) {
			this.field.setAttribute( this.fieldAttrs[ i ].name, this.fieldAttrs[ i ].value );
		}
		this.element.insertAdjacentElement( 'afterbegin', this.field );

		this.resetFileLabel();

		// trigger event for possible listeners
		this.trigger( 'deleted', null, false );
	}

	populateFileLabel( file ) {
		const humanFilesize = filesize( file.size );

		// eslint-disable-next-line no-unused-vars
		const [ filename, basename, fileExt ] = /(.*)\.(.+)$/.exec( file.name );

		this.labelInfo.innerText = `${ fileExt } - ${ humanFilesize }`;
		this.labelTitle.innerText = ( basename.length > FILE_MAX_FILENAME_LENGTH ? basename.slice( 0, FILE_MAX_FILENAME_LENGTH ).concat( '…' ) : basename );

		this.hasFile = true;
		this.label.classList.add( VALID_CLASS );
		this.trigger( 'selected', null, false );
	}

	resetFileLabel() {
		this.labelInfo.innerText = this.initialInfo;
		this.labelTitle.innerText = '';
		this.label.classList.remove( VALID_CLASS );
		this.hasFile = false;
	}

	setError( error ) {
		this.hasError = true;
		this.label.classList.add( 'has-error' );
		this.element.setAttribute( 'data-error', error );
		this.labelTitle.innerHTML = error;
	}

	resetError() {
		this.label.classList.remove( 'has-error' );
		this.element.removeAttribute( 'data-error' );
		this.labelTitle.innerHTML = '';
		this.hasError = false;
	}

	reset() {
		this.resetError();
		this.deleteFile();
	}

	focus() {
		this.field.focus();
	}

	getName() {
		return this.field.name;
	}

	show() {
		this.element.classList.remove( HIDDEN_CLASS );
	}

	hide() {
		this.element.classList.add( HIDDEN_CLASS );
	}
}
