/**
 * Removes the sticking hover state that
 * to clicked element (mostly on mobile).
 * This is an ugly work around but it seems to work.
 *
 * /!\ BE CARREFULL WHEN USING IT /!\
 * This is a costly operation because of the DOM manipulation.
 * Be sure to test thoroughly on low performance devices.
 * /!\ -------------------------- /!\
 *
 * Inspiration/CopyPaste
 *
 * @source https://stackoverflow.com/a/17234319/5078169
 *
 * @param {Node} el The element which the hover should be fixed.
 */
export default function fixHoverState( el ) {
	const par = el.parentNode;
	const next = el.nextSibling;
	par.removeChild( el );
	requestAnimationFrame( () => par.insertBefore( el, next ) );
}
