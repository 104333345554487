import BaseView from '../../../js/base-view';

const SELECTOR_BIO_CELL = '[data-info-cell-bio]';
const SELECTOR_CELL_CONTENT = '.info-cell__description';
const SELECTOR_TOGGLE_BUTTON = '[data-bio-toggle-btn]';

const CLASS_WRAPPER = 'expand-wrapper';
const CLASS_IS_HIDDEN = 'is-hidden';

const ATTR_TEXT_COLLAPSE = 'data-text-collapse';

const NB_LINES = 10;

export default class ProfileSkills extends BaseView {
	initialize() {
		const bio = this.element.querySelector( `${ SELECTOR_BIO_CELL } ${ SELECTOR_CELL_CONTENT }` );
		if ( bio === null ) {
			return false;
		}

		this.refs = {
			bio,
			button: this.element.querySelector( SELECTOR_TOGGLE_BUTTON ),
			btnSpan: this.element.querySelector( SELECTOR_TOGGLE_BUTTON ).firstElementChild,
		};

		const bioStyles = getComputedStyle( this.refs.bio );
		const lineHeight = parseInt( bioStyles.lineHeight );
		this.props = {
			maxBioHeight: NB_LINES * lineHeight,
			texts: {
				collapse: this.refs.button.getAttribute( ATTR_TEXT_COLLAPSE ),
				expand: this.refs.btnSpan.textContent,
			},
		};

		this.state = {
			isOpen: false,
		};

		if ( this.refs.bio.offsetHeight > this.props.maxBioHeight ) {
			this.makeCollapsable();
			this.collapse();
			this.refs.button.classList.remove( CLASS_IS_HIDDEN );
		}

		this.bindEvents();

		return this;
	}

	bindEvents() {
		this.on( 'click', SELECTOR_TOGGLE_BUTTON, this.toggle.bind( this ) );
	}

	makeCollapsable() {
		const wrap = document.createElement( 'DIV' );
		wrap.classList.add( CLASS_WRAPPER );
		wrap.innerHTML = this.refs.bio.innerHTML;
		this.refs.bio.textContent = '';
		this.refs.bio.appendChild( wrap );
		this.refs.wrapper = wrap;
	}

	toggle() {
		this.state.isOpen = ( this.state.isOpen ? this.collapse() : this.expand() );
	}

	expand() {
		this.refs.bio.style.height = `${ this.refs.wrapper.offsetHeight }px`;
		this.refs.btnSpan.textContent = this.props.texts.collapse;
		return true;
	}

	collapse() {
		this.refs.bio.style.height = `${ this.props.maxBioHeight }px`;
		this.refs.btnSpan.textContent = this.props.texts.expand;
		return false;
	}
}
