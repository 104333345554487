import BaseView from '../../../js/base-view';

const TITLE_SELECTOR = '.input-select__label-title';
const SELECT_SELECTOR = '.input-select__field';
const SELECTED_STATE_CLASS = 'is-selected';

export default class InputSelect extends BaseView {
	init() {
		this.title = this.getScopedElement( TITLE_SELECTOR );
		this.select = this.getScopedElement( SELECT_SELECTOR );

		this.on( 'change', SELECT_SELECTOR, this.handleSelectChange.bind( this ) );
		this.on( 'change', SELECT_SELECTOR, this.checkError.bind( this ) );
	}

	handleSelectChange() {
		this.updateSelectionTitle();
	}

	updateSelectionTitle() {
		if ( this.select.selectedIndex === 0 ) {
			this.element.classList.remove( SELECTED_STATE_CLASS );
		}
		else {
			this.title.innerText = this.select.children.item( this.select.selectedIndex ).text;
			this.element.classList.add( SELECTED_STATE_CLASS );
		}
	}

	getName() {
		return this.select.name;
	}

	checkError() {
		if ( ! this.element.hasAttribute( 'data-error' ) ) {
			return;
		}
		if ( this.select.checkValidity() ) {
			this.resetError();
		}
	}

	setError( error ) {
		this.element.setAttribute( 'data-error', error );
	}

	resetError() {
		this.element.removeAttribute( 'data-error' );
	}

	reset() {
		this.resetError();
		this.element.classList.remove( SELECTED_STATE_CLASS );
		this.title.innerText = this.select.children.item( 0 ).text;
	}

	focus() {
		this.select.focus();
	}
}
