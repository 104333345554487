import Barba from 'barba.js';
import componentsManager from './components-manager';

let COMPONENTS_TO_RELOAD = {};

const DynamicTransition = Barba.BaseTransition.extend( {
	start() {
		// console.log('Barba: start');
		document.body.classList.add( 'is-transitioning' );
		document.dispatchEvent(
			new CustomEvent( 'barba:start', {
				detail: {
					callback: () => {
						// console.log('Barba: start cb');
						componentsManager.unload( true );
						this.newContainerLoading
							.then( this.finish.bind( this ) );
					},
				},
			} ),
		);
	},

	finish() {
		document.body.scrollTop = 0;
		document.documentElement.scrollTop = 0;

		document.body.classList.remove( 'is-transitioning' );

		// Retrieve inline scripts to execute them
		const scripts = Array.from( this.newContainer.querySelectorAll( 'script' ) );
		// eslint-disable-next-line no-eval
		scripts.forEach( ( s ) => eval( s.innerHTML ) );

		if ( window.supt && window.supt.pageupdate ) {
			document.body.className = window.supt.pageupdate.bodyClass;
		}

		this.done(); // hide old content, show new content
		document.dispatchEvent(
			new CustomEvent( 'barba:finish', {
				detail: {
					callback: () => {
						// console.log('Barba: finish cb');
						componentsManager.load( COMPONENTS_TO_RELOAD );
						document.dispatchEvent( new CustomEvent( 'transition:end' ) );
					},
				},
			} ),
		);
	},
} );

// eslint-disable-next-line func-names
Barba.Pjax.getTransition = function() {
	return DynamicTransition;
};

export default function( componentsConfig ) {
	if ( document.querySelector( '#barba-wrapper' ) === null ) {
		return;
	}

	try {
		Barba.Pjax.Dom.wrapperId = 'barba-wrapper';
		Barba.Pjax.Dom.containerClass = 'barba-container';

		Barba.Pjax.start();
		Barba.Prefetch.init();

		COMPONENTS_TO_RELOAD = componentsConfig;
	}
	catch ( e ) {
		console.error( e );
	}
}
