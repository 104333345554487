import BaseView from '../../../js/base-view';

const LABEL_SELECTOR = '.filters__label';
const WRAP_SELECTOR = '.filters__wrap';
const LIST_SELECTOR = '.filters__list';
const NUMBER_SELECTOR = '.filters__badge';
const RESET_LINK_SELECTOR = '.filters__reset';

const RESET_LINK_DISABLED_CLASS = 'is-disabled';

export default class Filters extends BaseView {
	/**
	 * Init function
	 */
	initialize() {
		this.refs = {
			wrap: this.getScopedElement( WRAP_SELECTOR ),
			list: this.getScopedElement( LIST_SELECTOR ),
			number: this.getScopedElement( NUMBER_SELECTOR ),
			resetLink: this.getScopedElement( RESET_LINK_SELECTOR ),
		};

		this.state = {
			isOpen: false,
		};

		this.bindEvents();
	}

	bindEvents() {
		this.on( 'click', LABEL_SELECTOR, this.toggle.bind( this ) );
	}

	toggle() {
		if ( this.state.isOpen ) {
			this.refs.wrap.style.maxHeight = null;
			this.state.isOpen = false;
		}
		else {
			this.refs.wrap.style.maxHeight = `${ this.refs.list.offsetHeight }px`;
			this.state.isOpen = true;
		}
		this.element.setAttribute( 'aria-expanded', this.state.isOpen );
	}

	/**
	 * Update the DOM
	 *
	 * @param {Object} data the data received
	 */
	updateDOM( data ) {
		this.refs.wrap.innerHTML = data.html;
		this.refs.number.innerText = data.number;
		this.refs.list = this.getScopedElement( LIST_SELECTOR );

		this.refs.resetLink.classList.toggle( RESET_LINK_DISABLED_CLASS, data.reset.disabled );
	}

	getPostType() {
		return ( this.element.hasAttribute( 'data-post-type' )
			? this.element.getAttribute( 'data-post-type' )
			: 'all'
		);
	}
}
