export default function loadScript( url, callback ) {
	let done = false;

	const script = document.createElement( 'script' );
	script.type = 'text/javascript';
	script.async = true;
	script.src = url;

	if ( typeof callback === 'function' ) {
		const loadedCB = () => {
			const state = script.readyState;
			if ( ! done && ( ! state || /loaded|complete/.test( state ) ) ) {
				done = true;
				callback();
			}
		};

		script.onreadystatechange = loadedCB;
		script.onload = loadedCB;
	}

	document.head.appendChild( script );

	return script;
}
