/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */
import { isEmpty } from 'lodash-es';

import BaseView from '../../../js/base-view';
import requester from '../../../js/utils/requester';

import SearchBar from '../../organisms/search-bar/search-bar';
import ResultList from '../../organisms/result-list/result-list';

const SELECTOR_SEARCH_BAR = '[data-search-bar]';
const SELECTOR_RESULT_LIST = '[data-result-list]';

const DEFAULT_CONFIG = {
	json_base: 'http://localhost/wp-json',
	nbPerPage: 6,
	lang: 'fr',
	ot: null, // "ot" for "Auth",
};

const DEFAULT_STATE = {
	page: 1,
	searchValue: null,
	searchFilter: null,
	nbResults: 0,
};

const CURRENT_LANG = (
	( window.supt && window.supt.lang ) ? window.supt.lang : DEFAULT_CONFIG.lang
);

export default class PageProjectList extends BaseView {
	// #######################
	// #region Init
	// #######################

	initialize() {
		this.config = { ...DEFAULT_CONFIG, ...window.supt.projects, lang: CURRENT_LANG };

		this.refs = {
			searchBar: new SearchBar( this.element.querySelector( SELECTOR_SEARCH_BAR ) ).init(),
			projectList: new ResultList( this.element.querySelector( SELECTOR_RESULT_LIST ) )
				.init( { renderCard: this.renderCard } ),
		};

		this.state = new Proxy( { ...DEFAULT_STATE }, { set: this.stateChange.bind( this ) } );

		this.bindEvents();
	}

	/**
	 * Trap handler for the state object
	 *
	 * @param {Object} state Current state object
	 * @param {string} property The name of the property to set in the state
	 * @param {any} value The new value of the property to set
	 *
	 * @return {boolean} Indicate wether or not the assignment succeeded
	 */
	stateChange( state, property, value ) {
		if ( state[ property ] === value ) {
			return true;
		}
		state[ property ] = value;

		switch ( property ) {
			case 'searchValue':
				state.page = 1;
				state.searchFilter = null;
				break;

			case 'searchFilter':
				state.page = 1;
				break;

			case 'nbResults':
				this.refs.searchBar.updateInputNbResults( value );
				break;

			case 'page':
			default:
				// nothing to do
				break;
		}
		return true;
	}

	bindEvents() {
		this.on( 'search:change', SELECTOR_SEARCH_BAR, this.onSearchChange.bind( this ) );
		this.on( 'search:submit', SELECTOR_SEARCH_BAR, this.onSubmit.bind( this ) );

		this.on( 'next-page', SELECTOR_RESULT_LIST, this.onNextPage.bind( this ) );
	}

	// #######################
	// #endregion
	// #######################

	// #######################
	// #region Event Handlers
	// #######################

	onSearchChange( { detail } ) {
		this.state.searchValue = detail;

		this.query( true );
	}

	onSubmit( { detail } ) {
		this.state.searchValue = detail.text;
		this.state.searchFilter = detail.filter;

		this.query();
	}

	onNextPage() {
		this.state.page += 1;

		this.query();
	}

	// #######################
	// #endregion
	// #######################

	// #######################
	// #region ACTIONS
	// #######################

	query( isSuggestion = false ) {
		const terms = this.getTerms();
		const params = {
			type: 'post',
			subtype: 'page',
			per_page: this.config.nbPerPage,
			page: this.state.page,
			search: this.state.searchValue,
			suggest: isSuggestion,
			...terms,
		};

		this.state.queryId = performance.now();

		requester( {
			name: 'pageProjectList:query',
			url: `${ this.config.json_base }/supt/v1/projects`,
			method: 'get',
			params,
			id: this.state.queryId,
		} )
			.then( ( { id, data } ) => {
				// Do not process if it's not the latest request
				if ( id !== this.state.queryId ) {
					return;
				}

				this.state.nbResults = ( isEmpty( this.state.searchValue ) && isEmpty( terms ) )
					? 0
					: data.total;

				if ( isSuggestion ) {
					this.refs.searchBar.updateInputSuggestions(
						data.items.map( ( item ) => ( { value: item.data.title } ) )
					);
				}
				else {
					const hasMoreItemsToLoad = data.max_pages > this.state.page;
					this.refs.projectList[ ( this.state.page > 1 ) ? 'addPage' : 'setList' ]( data.items, ! hasMoreItemsToLoad );

					this.refs.searchBar.updateFilters( {
						status: data.aggregations.status,
						thematics: data.aggregations.thematics,
					} );
				}
			} );
	}

	getTerms() {
		const terms = {};
		if ( ! isEmpty( this.state.searchFilter ) ) {
			Object.keys( this.state.searchFilter )
				.filter( ( name ) => ! isEmpty( this.state.searchFilter[ name ] ) )
				.forEach( ( name ) => {
					terms[ name ] = this.state.searchFilter[ name ];
				} );
		}

		return terms;
	}

	renderCard( { modifiers = [], data = {} } ) {
		const hlevel = `h${ data?.level || 2 }`;
		return `
		<article class="card-research appear-enter ${ modifiers.join( ' ' ) } ">
			<div class="card-research__inner">
				<div class="card-research__header">
					<div class="card-research__status">${ data.status }</div>
					<svg role="img" alt="Projet de recherche" class="card-research__icon">
						<use href="#project"/>
					</svg>
				</div>
				<div class="card-research__content">
					<${ hlevel } class="card-research__title">${ data.title }</${ hlevel }>
					<ul class="card-research__details">
					${ data.details.map( ( detail ) => ( detail.description === '' ? '' : `
						<li class="card-research__detail-item">
							<svg class="card-research__detail-icon" role="img" alt="${ detail.description }">
								<use href="#${ detail.icon }"/>
							</svg>
							<div class="card-research__detail-desc">${ detail.description }</div>
						</li>` ) ).join( '' ) }
					</ul>
					<div class="wp-block-button is-style-primary">
						<a
							class="wp-block-button__link"
							href="${ data.button.link }"
							${ data.button.target ? `target="${ data.button.target }"` : '' }
						>
							${ data.button.title }
						</a>
					</div>
				</div>
			</div>
		</article>`;
	}

	// #######################
	// #endregion
	// #######################
}
