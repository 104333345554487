import { isEmpty } from 'lodash-es';
import BaseView from '../../../js/base-view';

const FILLED_CLASS = 'is-filled';
const ACTIVE_CLASS = 'is-focus';

const FIELD_SELECTOR = '.input__field';

/**
 * @class Input
 */
export default class Input extends BaseView {
	init() {
		// Select the field
		this.field = this.getScopedElement( FIELD_SELECTOR );

		this.bind();
	}

	bind() {
		switch ( this.field.type ) {
			case 'radio':
			case 'checkbox': {
				const parent = this.element.parentElement;
				this.errorEl = parent.classList.contains( 'choice-list' ) ? parent : this.element;
				break;
			}

			default:
				this.errorEl = this.element;
				// Bind change event section.
				this.on( 'keyup', FIELD_SELECTOR, this.setIsFilled.bind( this ) );
				this.on( 'change', FIELD_SELECTOR, this.setIsFilled.bind( this ) );
				this.on( 'focus', FIELD_SELECTOR, this.setIsActive.bind( this ) );
				this.on( 'blur', FIELD_SELECTOR, this.setIsActive.bind( this ) );
				this.on( 'blur', FIELD_SELECTOR, this.checkError.bind( this ) );

				// Make sure if the input is not empty
				// the label is put in the correct state
				this.setIsFilled();
		}
	}

	/**
	 * Set the state of is_filled and it's class to the element
	 */
	setIsFilled() {
		if ( isEmpty( this.field.value ) ) {
			this.element.classList.remove( FILLED_CLASS );
		}
		else {
			this.element.classList.add( FILLED_CLASS );
		}
	}

	setIsActive( event ) {
		if ( event.type === 'focus' ) {
			this.element.classList.add( ACTIVE_CLASS );
		}
		else {
			this.element.classList.remove( ACTIVE_CLASS );
		}
	}

	getName() {
		return this.field.name.replace( /(\[.*\])/, '' );
	}

	checkError() {
		if ( ! this.errorEl.hasAttribute( 'data-error' ) ) {
			return;
		}
		if ( this.field.checkValidity() ) {
			this.resetError();
		}
	}

	reset() {
		this.element.classList.remove( FILLED_CLASS );
		this.element.classList.remove( ACTIVE_CLASS );
		this.resetError();
	}

	setError( error ) {
		this.errorEl.setAttribute( 'data-error', error );
	}

	resetError() {
		this.errorEl.removeAttribute( 'data-error' );
	}

	focus() {
		this.field.focus();
	}
}
