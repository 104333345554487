import BaseView from '../../../js/base-view';

import InputFileView from '../../atoms/input/input-file';

const INPUT_SELECTOR = '[data-input-file]';

const HIDDEN_CLASS = 'is-hidden';

export default class UploadFile extends BaseView {
	init() {
		this.min = parseInt( this.element.getAttribute( 'data-min' ) );
		this.max = parseInt( this.element.getAttribute( 'data-max' ) );
		this.nbSelected = 0;

		this.items = Array.from( this.getScopedElements( INPUT_SELECTOR ) )
			.map( ( element ) => {
				const v = new InputFileView( element );
				v.init();
				return {
					input: v,
					li: element.parentElement,
				};
			} );

		this.name = this.items[ 0 ].input.getName().replace( '[]', '' );

		this.toggleItems();

		this.bind();
	}

	bind() {
		this.on( 'selected', INPUT_SELECTOR, this.onSelected.bind( this ) );
		this.on( 'deleted', INPUT_SELECTOR, this.onDeleted.bind( this ) );
	}

	onSelected() {
		this.nbSelected += 1;
		this.toggleItems();
	}

	onDeleted() {
		this.nbSelected -= 1;
		this.toggleItems();
	}

	toggleItems() {
		for ( let i = this.min; i < this.max; i++ ) {
			if ( this.nbSelected >= i ) {
				this.showItem( this.items[ i ] );
			}
			else {
				this.hideItem( this.items[ i ] );
			}
		}
	}

	hideItem( item ) {
		item.input.hide();
		setTimeout( () => {
			item.li.classList.add( HIDDEN_CLASS );
		}, 310 ); // linked to CSS value in input-file.is-hidden
	}

	showItem( item ) {
		item.li.classList.remove( HIDDEN_CLASS );
		setTimeout( () => {
			item.input.show();
		}, 10 );
	}

	getName() {
		return this.name;
	}

	reset() {
		this.items.forEach( ( { input } ) => input.reset() );
		this.nbSelected = 0;
	}

	focus() {
		this.items[ 0 ].input.focus();
	}

	/**
	 *
	 * @param {Object} errors An Object with the error of each file item
	 */
	setError( errors ) {
		Object.keys( errors ).forEach( ( i ) => {
			this.items[ i ].input.setError( errors[ i ] );
		} );
	}

	resetError() {
		this.element.removeAttribute( 'data-error' );
	}
}
