/**
 * Get all direct descendant elements that match a selector
 *
 * @param  {Node}   elem     The element to get direct descendants for
 * @param  {string} selector The selector to match against
 * @return {Array}           The matching direct descendants
 */
// eslint-disable-next-line func-names
const getChildrenMatches = function( elem, selector ) {
	return Array.prototype.filter.call( elem.children, ( child ) => child.matches( selector ) );
};

export default getChildrenMatches;
