/* global Sentry */
import scrollAppear from './scroll-appear';

// "private" vars
const LOADED_COMPONENTS = {};

export default {

	/**
	 * Load all the given components.
	 * All the component are built using the same interface, so...
	 * the .bind method does the magic for everyone.
	 *
	 * @param {Array} componentsConfig components to unload
	 */
	load( componentsConfig ) {
		for ( let i = 0; i < componentsConfig.length; i++ ) {
			const {
				selector, view, name, global,
			} = componentsConfig[ i ];
			const element = document.querySelectorAll( selector );
			// Only if the DOM element exists
			// we load the view.
			if ( element ) {
				for ( let j = 0; j < element.length; j++ ) {
					// Create the view instance
					// and push the view in the loaded view cache object.
					try {
						// eslint-disable-next-line new-cap
						const v = new view( element[ j ], !! global );
						v.bind();
						LOADED_COMPONENTS[ name + j ] = v;
					}
					catch ( error ) {
						if ( typeof Sentry !== 'undefined' ) {
							Sentry.captureException( error );
						}
						else {
							console.error( 'Could not init view:', name, '⬇️' );
							console.error( error );
						}
					}
				}
			}
		}

		// DEBUG: Exposes the loaded views for dev purposes
		// window.COMPONENTS = LOADED_COMPONENTS;

		/**
		 * Init: scroll appear
		 */
		scrollAppear.init();

		return LOADED_COMPONENTS;
	},

	/**
	 * Unload all loaded components.
	 *
	 * @param ignoreGlobal
	 */
	unload( ignoreGlobal = false ) {
		/**
		 * Destroy: scroll appear
		 */
		scrollAppear.destroy();

		Object.keys( LOADED_COMPONENTS ).forEach( ( cKey ) => {
			if (
				LOADED_COMPONENTS[ cKey ] &&
				LOADED_COMPONENTS[ cKey ].destroy &&
				( // if ignore global, check if not-global
					! ignoreGlobal ||
					! LOADED_COMPONENTS[ cKey ].global
				)
			) {
				LOADED_COMPONENTS[ cKey ].destroy();
				delete LOADED_COMPONENTS[ cKey ];
			}
		} );
	},
};
