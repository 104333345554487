import objectToQueryString from './utils/objectToQueryString';

const DEFAULT_OPTIONS = {
	method: 'POST',
	credentials: 'same-origin',
	cache: 'no-cache',
	headers: {
		Accepts: 'application/json',
		'Content-Type': 'application/x-www-form-urlencoded;',
	},
};

export default class Fetcher {
	constructor( props = {}, fetchUrl = '#' ) {
		this.props = {
			query: {},
			action: 'loadmore',
			options: DEFAULT_OPTIONS,
			isFake: false,
			fakeData: {},
			...props,
		};

		this.state = {
			fetchUrl,
			isFetching: false,
		};
	}

	fetch( url ) {
		this.state.isFetching = true;

		if ( typeof url !== 'undefined' ) {
			this.state.fetchUrl = url;
		}

		return (
			this.props.isFake
				? this.fakeFetch()
				: this.realFetch()
		)
			.then( ( data ) => {
				this.state.isFetching = false;
				return data;
			} );
	}

	/**
	 * Fetch the content (fo' realz)
	 *
	 * @return {Promise} a promise resolving with JSON object or rejecting with an error object
	 */
	realFetch() {
		const data = {
			action: this.props.action,
			query: this.props.query,
		};

		return window.fetch(
			this.state.fetchUrl,
			{ ...this.props.options, body: objectToQueryString( data ) },
		)
			// check success
			.then( ( response ) => {
				if ( response.status >= 200 && response.status < 300 ) {
					return response;
				}
				const error = new Error( response.statusText );
				error.response = response;
				throw error;
			} )
			.then( ( response ) => response.json() )
			.then( ( result ) => {
				if ( result.success ) {
					return result.data;
				}

				const error = new Error( 'Server error' );
				throw error;
			} );
	}

	/**
	 * Fetch the content (fo' fakezzz)
	 *
	 * @return {Promise} a promise resolving with html as a string or rejecting with an error object
	 */
	fakeFetch() {
		return new Promise( ( resolve ) => {
			setTimeout( () => resolve( this.props.fakeData ), 2000 );
		} );
	}
}
