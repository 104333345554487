
/**
 * Tabby is used for accessible JS toggle tabs
 * Docs: https://github.com/cferdinandi/tabby
 */
import Tabby from 'tabbyjs';

/**
 * Hammer.js is used for swiping the tabs content
 * Docs: http: //hammerjs.github.io/getting-started
 */
import Hammer from 'hammerjs';
import BaseView from '../../../js/base-view';

/* eslint-disable */

const TABS_SELECTOR        = '[data-weekly-schedule-tabs]';
const TAB_ITEM_SELECTOR    = `${TABS_SELECTOR} a`;
const DEFAULT_TAB_SELECTOR = '[data-weekly-schedule-default-tab]';
const TAB_ID_PREFIX        = 'weekly-schedule-tabs_';
const CONTENT_SELECTOR     = '[data-weekly-schedule-content]';
const SWIPE_DIRECTION      = {
	2: 'left',
	4: 'right',
};

/* eslint-enable */

/**
 * Required DOM attributes:
 * [data-weekly-schedule-tabs]        - the tabs container
 * [data-weekly-schedule-default-tab] - the default active tab
 * [data-weekly-schedule-content]     - swipeable areas
 *
 * Tabs and content are linked through a matching id attribute
 */
export default class WeeklySchedule extends BaseView {
	bind() {
		// set defaults
		this.currentSwipeDirection = null;

		// save a reference of the tabs so that later on we can toggle them dynamically
		this.tabElements = Array.from( this.element.querySelectorAll( TAB_ITEM_SELECTOR ) );

		// set a unique attr on the root element of this component to be able
		// to have a unique selector for Tabby
		this.element.setAttribute( 'data-tabby-id', this.vid );
		this.tabbySelector = `[data-tabby-id="${ this.vid }"] ${ TABS_SELECTOR }`;
		this.tabsContainer = this.element.querySelector( this.tabbySelector );

		// initialize Tabby for tabs
		this.tabs = new Tabby( this.tabbySelector, {
			idPrefix: TAB_ID_PREFIX,
			default: DEFAULT_TAB_SELECTOR,
		} );

		// initialize Hammer for swiping
		this.hammer = new Hammer( this.element );

		// find initial active tab and save the currentTabIndex
		const activeTab = this.element.querySelector( DEFAULT_TAB_SELECTOR );
		this.setTabIndex( this.getArrayIndex( this.tabElements, activeTab ) );

		this.bindEventListeners();

		this.setFirstLastTabPaddings();
		viewport_service.on( 'change', this.setFirstLastTabPaddings.bind( this ) );
	}

	bindEventListeners() {
		this.hammerSwipeListener = this.handleSwipe.bind( this );
		this.tabbyEventListener = this.handleTabEvent.bind( this );

		this.hammer.on( 'swipe', this.hammerSwipeListener );
		this.on( 'tabby', this.tabbyEventListener );
	}

	handleTabEvent( event ) {
		this.setTabIndex( this.getArrayIndex( this.tabElements, event.target ) );
		this.centerActiveTab();
	}

	handleSwipe( event ) {
		// bail early if not swiping the correct element
		const contentSwiped = event.target.closest( CONTENT_SELECTOR );
		if ( ! contentSwiped ) {
			return;
		}

		this.currentSwipeDirection = SWIPE_DIRECTION[ event.direction ];

		if ( this.currentSwipeDirection === 'left' && ! this.isLastTab() ) {
			this.nextTab();
		}
		else if ( this.currentSwipeDirection === 'right' && ! this.isFirstTab() ) {
			this.previousTab();
		}
	}

	nextTab() {
		this.setTabIndex( this.currentTabIndex + 1 );
		this.toggleTab( this.tabElements[ this.currentTabIndex ].getAttribute( 'href' ) );
	}

	previousTab() {
		this.setTabIndex( this.currentTabIndex - 1 );
		this.toggleTab( this.tabElements[ this.currentTabIndex ].getAttribute( 'href' ) );
	}

	toggleTab( idSelector ) {
		this.tabs.toggle( idSelector );
		this.centerActiveTab();
	}

	centerActiveTab() {
		const currentTabNode = this.tabElements[ this.currentTabIndex ].parentNode;

		// get the x center of the tabs container
		const tabsContainerRect = this.tabsContainer.getBoundingClientRect();
		const tabsContainerXCenter = tabsContainerRect.width / 2;

		// get the x center of the active tab
		const currentTabRect = currentTabNode.getBoundingClientRect();
		const currentTabXCenter = currentTabNode.offsetLeft + ( currentTabRect.width / 2 );

		// calculate scroll amount
		const scrollPosition = currentTabXCenter - tabsContainerXCenter;

		this.tabsContainer.scroll( {
			left: scrollPosition,
			behavior: 'smooth',
		} );
	}

	isFirstTab() {
		return this.currentTabIndex === 0;
	}

	isLastTab() {
		return this.currentTabIndex === ( this.tabElements.length - 1 );
	}

	getArrayIndex( array, element ) {
		return array.indexOf( element );
	}

	setTabIndex( index ) {
		this.currentTabIndex = index;
	}

	setFirstLastTabPaddings() {
		const containerRect = this.tabsContainer.getBoundingClientRect();
		const firstTab			= this.tabElements[ 0 ];
		const firstItem			= firstTab.parentElement;
		const lastTab				= this.tabElements[ this.tabElements.length - 1 ];
		const lastItem			= lastTab.parentElement;

		// Reset to 0 to correctly calculated if padding is needed
		firstItem.style.paddingLeft = null;
		lastItem.style.paddingRight = null;

		const itemsWidth = this.tabElements
			.map( ( tab ) => tab.getBoundingClientRect().width )
			.reduce( ( accumulator, currentValue ) => accumulator + currentValue );

		if ( itemsWidth > containerRect.width ) {
			firstItem.style.paddingLeft = `${ ( containerRect.width / 2 ) - ( firstTab.getBoundingClientRect().width / 2 ) }px`;
			lastItem.style.paddingRight = `${ ( containerRect.width / 2 ) - ( lastTab.getBoundingClientRect().width / 2 ) }px`;
		}
	}

	destroy() {
		// Sometimes tabby fail to destroy;
		try {
			this.tabs.destroy();
			this.hammer.destroy();
		}
		catch ( error ) {
			console.error( 'Could not destroy tabby tabs' );
		}

		super.destroy();
	}
}
