import TimelineLite from 'gsap/TimelineLite';
import { debounce } from 'lodash-es';
import BaseView from '../../../js/base-view';

const CLASS_SUBMENU_ITEM = 'profile-submenu__item';
const CLASS_SUBMENU_ITEM_CURRENT = 'profile-submenu__item--current';

const SELECTOR_INNER = '.profile-submenu__inner';
const SELECTOR_LIST = '.profile-submenu__list';
const SELECTOR_ITEM = '.profile-submenu__item';
const SELECTOR_UNDERLINE = '.profile-submenu__underline';
const SELECTOR_ANCHOR = '[data-profile-anchor]';

const BREAKPOINT_DESKTOP_MODE = 1024; // submenu should be in desktop version when >= …px
const UNDERLINE_DELAY = 0.3;
const DEBOUNCE_DELAY = 10; // ms
const MENU_HEIGHT_DESKTOP_SMALL = 96;
const MENU_HEIGHT_MOBILE_SMALL = 68;

export default class ProfileSubmenu extends BaseView {
	bind() {
		// component state
		this.state = {
			isMobile: undefined,
			currentSection: 0,
			menuHeight: MENU_HEIGHT_DESKTOP_SMALL,
		};

		// cache
		// - gets updated on state change
		// - we use it to avoid accessing the dom too much
		this.cache = {
			elCurrentRootSubmenu: null,
			tlUnderline: new TimelineLite(), // used for underline animation
			isUnderlineInit: false,
			isUnderlineActive: false,
		};

		// dom elements
		this.el = {
			submenuInner: this.getScopedElement( SELECTOR_INNER ),
			submenuList: this.getScopedElement( SELECTOR_LIST ),
			submenuItems: null,
			submenuCurrent: null,
			submenuUnderline: this.getScopedElement( SELECTOR_UNDERLINE ),
			anchors: Array.from( document.querySelectorAll( SELECTOR_ANCHOR ) ),
		};

		this.state.isMobile = this.isMobile();
		this.populateSubmenu();
		this.updateSectionThresholds();
		this.handleViewportResize();
		this.handleSubmenuActive();
		this.handleSubmenuScrollX();
		this.bindEventListeners();
	}

	bindEventListeners() {
		// viewport
		viewport_service.on( 'change', () => this.handleViewportResize() );

		// horizontal scroll on mobile
		const onScrollSubmenu = debounce( this.handleSubmenuScrollX.bind( this ), DEBOUNCE_DELAY );
		this.on( 'scroll', SELECTOR_INNER, onScrollSubmenu );

		// page scroll
		this.onScrollWindow = debounce( this.handleSubmenuActive.bind( this ), DEBOUNCE_DELAY );
		// viewport scroll to update active submenu
		window.addEventListener( 'scroll', this.onScrollWindow, { passive: true } );

		this.on( 'click', ( ev ) => this.scrollToAnchor( ev ) );

		// hover effect on submenu
		this.on( 'mouseenter', SELECTOR_ITEM, ( ev ) => this.handleSubmenuItemMouseenter( ev ) );
		this.on( 'mouseleave', SELECTOR_ITEM, ( ev ) => this.handleSubmenuItemMouseleave( ev ) );
	}

	/* SETUP */
	/**
	 * Populate submenu with items according to profile sections
	 */
	populateSubmenu() {
		this.el.anchors.forEach( ( anchor ) => {
			const anchorTitle = anchor.dataset.profileAnchor;
			const anchorHref = `#${ anchor.id }`;
			const item = document.createElement( 'A' );
			item.setAttribute( 'class', CLASS_SUBMENU_ITEM );
			item.href = anchorHref;
			item.innerHTML = anchorTitle;
			this.el.submenuList.appendChild( item );
		} );

		// setup submenu variables
		this.el.submenuItems = this.getScopedElements( SELECTOR_ITEM );
		this.el.submenuCurrent = this.el.submenuItems[ this.state.currentSection ];
		this.el.submenuCurrent.classList.add( CLASS_SUBMENU_ITEM_CURRENT );
	}

	/**
	 * Update section anchors borders
	 */
	updateSectionThresholds() {
		const lng = this.el.anchors.length;

		this.state.sections = this.el.anchors.map( ( anchor, i ) => {
			const nextAnchor = ( i < lng - 1 ) ? this.el.anchors[ i + 1 ] : null;

			return {
				title: anchor.dataset.profileAnchor,
				start: anchor.offsetTop,
				end: ( nextAnchor ? nextAnchor.offsetTop : null ),
			};
		} );
	}

	/* VIEWPORT */
	/**
	 * When screen size changes
	 */
	handleViewportResize() {
		this.state.isMobile = this.isMobile();
		this.state.menuHeight = this.state.isMobile
			? MENU_HEIGHT_MOBILE_SMALL
			: MENU_HEIGHT_DESKTOP_SMALL;

		this.updateSectionThresholds();
		this.updateSubmenuStyle();
	}

	/**
	 * Update style of submenu
	 */
	updateSubmenuStyle() {
		if ( this.state.isMobile ) {
			this.updateSubmenuItemPos();
		}
		else {
			// add initial underline
			this.submenuUnderlineMoveTo( this.el.submenuCurrent );
		}
	}

	/**
	 * Return if viewport mobile
	 */
	isMobile() {
		return viewport_service.getCurrentWidth() < BREAKPOINT_DESKTOP_MODE;
	}

	/* SCROLL */
	/**
	 * Scroll to anchor
	 *
	 * @param {Event} ev Original event
	 */
	scrollToAnchor( ev ) {
		if ( ev.target.parentElement === this.el.submenuList ) {
			ev.preventDefault();
			// update current section index
			this.state.currentSection = Array.prototype.indexOf.call( this.el.submenuItems, ev.target );
			this.updateCurrentSubmenuItem();
			this.state.scrolling = true;
			const anchor = document.getElementById( ev.target.hash.slice( 1 ) );
			const scrollTo = anchor.offsetTop - ( this.state.menuHeight + this.element.clientHeight );
			window.scrollTo( {
				top: scrollTo,
				behavior: 'smooth',
			} );
		}
	}

	/**
	 * When submenu is scrolled horizontally
	 */
	handleSubmenuScrollX() {
		if ( this.el.submenuInner.scrollLeft <= 0 ) {
			this.element.classList.add( 'profile-submenu--start' );
		}
		else if ( this.el.submenuInner.scrollLeft >=
			( this.el.submenuInner.scrollWidth - this.el.submenuInner.clientWidth ) ) {
			this.element.classList.add( 'profile-submenu--end' );
		}
		else {
			this.element.classList.remove( 'profile-submenu--start', 'profile-submenu--end' );
		}
	}

	/**
	 * When window is scrolled
	 */
	handleSubmenuActive() {
		const newIdx = this.getNextSectionIdx();
		if ( this.state.currentSection === newIdx ) {
			return;
		}

		// update current section index
		this.state.currentSection = newIdx;
		this.updateCurrentSubmenuItem();
	}

	/* CURRENT */
	/**
	 * Return next section index
	 */
	getNextSectionIdx() {
		let nextIdx = false;
		let i = this.state.sections.length;
		const scrollYPos = window.pageYOffset + window.innerHeight / 2;

		do {
			i -= 1;
			const sct = this.state.sections[ i ];

			if ( scrollYPos > sct.start || i === 0 ) {
				nextIdx = i;
			}
		} while ( nextIdx === false );
		if ( this.state.scrolling ) {
			if ( nextIdx === this.state.currentSection ) {
				this.state.scrolling = false;
			}
			return this.state.currentSection;
		}

		return nextIdx;
	}

	/**
	 * Update current submenu link
	 */
	updateCurrentSubmenuItem() {
		this.element.classList.remove( CLASS_SUBMENU_ITEM_CURRENT, this.el.submenuCurrent );
		this.el.submenuCurrent = this.el.submenuItems[ this.state.currentSection ];
		this.el.submenuCurrent.classList.add( CLASS_SUBMENU_ITEM_CURRENT );
		this.updateSubmenuStyle();
	}

	/**
	 * Center current item if mobile
	 */
	updateSubmenuItemPos() {
		const submenuCurrentPos = this.el.submenuCurrent.offsetLeft;
		const submenuCurrentWidth = this.el.submenuCurrent.clientWidth;
		const submenuInnerWidth = this.el.submenuInner.clientWidth;
		if ( this.el.submenuCurrent === this.el.submenuItems[ 0 ] ) {
			this.el.submenuInner.scrollLeft = 0;
		}
		else if ( this.el.submenuCurrent === this.el.submenuItems[ this.el.submenuItems.length - 1 ] ) {
			this.el.submenuInner.scrollLeft = this.el.submenuInner.scrollWidth;
		}
		else {
			this.el.submenuInner.scrollLeft = submenuCurrentPos - submenuInnerWidth / 2 +
				( submenuCurrentWidth / 2 );
		}
	}

	/* UNDERLINE */
	/**
	 * When user hovers a submenu item
	 *
	 * @param {Event} ev Original event
	 */
	handleSubmenuItemMouseenter( ev ) {
		if ( this.state.isMobile ) {
			return;
		}

		this.submenuUnderlineMoveTo( ev.target );
	}

	/**
	 * When user stops hovering a submenu item
	 */
	handleSubmenuItemMouseleave() {
		if ( this.state.isMobile ) {
			return;
		}

		this.submenuUnderlineReset();
	}

	/**
	 * Show underline on hovered/active submenu link
	 *
	 * @param {Element} newEl
	 * @param {number}  delay
	 */
	submenuUnderlineMoveTo( newEl, delay = 0 ) {
		// vars
		const speedMove = this.cache.isUnderlineActive ? 1 : 0; // animate only if already active
		const speedScale = this.cache.isUnderlineInit ? 1 : 0; // animate only if initialized
		const underlineTranslateX = newEl.offsetLeft;
		const underlineScaleX = newEl.offsetWidth / this.el.submenuUnderline.offsetWidth;

		// update cache
		this.cache.isUnderlineActive = true;
		this.cache.isUnderlineInit = true;

		// animate
		this.cache.tlUnderline.kill().clear()
			.to( this.el.submenuUnderline, 0.3 * speedMove, {
				css: {
					x: underlineTranslateX,
					transformOrigin: '0% 0%',
				},
				delay,
			}, 0 )
			.to( this.el.submenuUnderline, 0.3 * speedScale, {
				css: {
					scaleX: underlineScaleX,
					transformOrigin: '0% 0%',
				},
				delay,
			}, 0 )
			.to( this.el.submenuUnderline, 0.3, {
				css: {
					opacity: 1,
				},
				delay,
			}, 0 );
	}

	/**
	 * Reset underline
	 */
	submenuUnderlineReset() {
		// if there's an active item to go to: move
		if ( this.el.submenuCurrent ) {
			this.submenuUnderlineMoveTo( this.el.submenuCurrent, UNDERLINE_DELAY );
		}
	}

	destroy() {
		super.destroy();

		// page scroll
		window.removeEventListener( 'scroll', this.onScrollWindow, { passive: true } );
	}
}
