import BaseView from '../../../js/base-view';

export default class Breadcrumbs extends BaseView {
	/**
	 * Init function.
	 */
	initialize() {
		const bc = this.getScopedElements( 'span > span > span > span' );
		if ( bc != null && bc.length ) {
			// we are on sub-sub-page or deeper!
			this.element.setAttribute( 'data-breadcrumbs', bc.length );
		}
	}
}
