import BaseView from '../../../js/base-view';

const CLASS_SHOW_ALL = 'show-all';
const CLASS_ENTER_SHOW_ALL = 'enter-show-all';

const PAGINATION_BUTTON_SELECTOR = '.section-card-research__pagination button';

export default class SectionCardResearch extends BaseView {
	bind() {
		// Bail early if no pagination
		if ( ! this.element.querySelector( PAGINATION_BUTTON_SELECTOR ) ) {
			this.destroy();
			return;
		}

		this.on( 'click', PAGINATION_BUTTON_SELECTOR, this.onPaginationClick.bind( this ) );
	}

	onPaginationClick( ev ) {
		ev.preventDefault();

		this.element.classList.add( CLASS_ENTER_SHOW_ALL );
		setTimeout( () => {
			this.element.classList.add( CLASS_SHOW_ALL );
			setTimeout( () => this.element.classList.remove( CLASS_ENTER_SHOW_ALL ), 500 );
		}, 10 );

		this.destroy();
	}
}
