import BaseView from '../../../js/base-view';

const VIDEO_CONTAINER_SELECTOR = '.video__video';
const VIDEO_WRAPPER_SELECTOR = '.video__wrapper';
const IS_PLAYING_CLASS = 'is-playing';

export default class Video extends BaseView {
	bind() {
		this.videoContainer = this.getScopedElement( VIDEO_CONTAINER_SELECTOR );
		this.videoWrapper = this.getScopedElement( VIDEO_WRAPPER_SELECTOR );
		this.video_url = this.element.getAttribute( 'data-video' );
		this.video = this.parseVideo( this.video_url );
		this.bindEvents();
	}

	bindEvents() {
		this.on( 'click', VIDEO_WRAPPER_SELECTOR, this.handlePlay.bind( this ) );
	}

	handlePlay() {
		this.attachVideo();
	}

	attachVideo() {
		this.element.classList.add( IS_PLAYING_CLASS );
		this.videoContainer.innerHTML = `<iframe src="${ this.video.embedUrl }" frameborder="0"></iframe>`;
	}

	/**
	 * Parses given url and returns object with usable information
	 *
	 * @param  {string} url The original video URL
	 * @return {Object}     Video id, provider and embedable url
	 *
	 * Supported YouTube URL formats
	 * - http://www.youtube.com/watch?v=My2FRPA3Gf8
	 * - http://youtu.be/My2FRPA3Gf8
	 * - https://youtube.googleapis.com/v/My2FRPA3Gf8
	 *
	 * Supported Vimeo URL formats
	 * - http://vimeo.com/25451551
	 * - http://player.vimeo.com/video/25451551
	 *
	 * Also supports relative URLs
	 *  - //player.vimeo.com/video/25451551
	 */
	parseVideo( url ) {
		const pattern = /(http:|https:|)\/\/(player.|www.)?(vimeo\.com|youtu(be\.com|\.be|be\.googleapis\.com))\/(video\/|embed\/|watch\?v=|v\/)?([A-Za-z0-9._%-]*)(\&\S+)?/;
		const matches = pattern.exec( url );

		const [ , , , source, , , id ] = matches;
		let provider = '';
		let embedUrl = '';

		if ( matches !== null ) {
			if ( source.indexOf( 'youtu' ) > -1 ) {
				provider = 'youtube';
				embedUrl = `https://www.youtube.com/embed/${ id }?rel=0&showinfo=0&autoplay=1&color=white&controls=1`;
			}
			else if ( source.indexOf( 'vimeo' ) > -1 ) {
				provider = 'vimeo';
				embedUrl = `https://player.vimeo.com/video/${ id }?autoplay=1`;
			}
		}

		return {
			id,
			provider,
			embedUrl,
		};
	}
}
